import React from 'react';
import axios from 'axios';
import {
    Button,
    CircularProgress,
    Box,
    LinearProgress,
    Typography,
} from '@mui/material';
import { HOST } from '../../../../v1apiRequest';

export const UploadButtonWithProgress = ({
    activeStep,
    params,
    completeFunc,
    failFunc,
}) => {
    const [progress, setProgress] = React.useState(0.0);
    const [isUploading, setIsUploading] = React.useState(false);
    const sendVideo = () => {
        console.log('push');
        setIsUploading(true);
        postVideoWithProgress(
            params,
            setProgress,
            completeFunc,
            failFunc,
            setIsUploading
        );
    };

    if (activeStep === 3) {
        return (
            <div style={{ marginTop: '20px', paddingBottom: '100px' }}>
                {isUploading ? (
                    <>
                        <span style={{ color: 'white' }}>送信中</span>

                        <CircularProgress size={20} />
                        <div>
                            <LinearProgressWithLabel progress={progress} />
                        </div>
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={sendVideo}
                    >
                        動画を送信
                    </Button>
                )}
            </div>
        );
    }
    return <></>;
};

function setTokenToHeader() {
    const authToken = localStorage.getItem('access');
    if (authToken !== 'undefined') {
        return 'JWT ' + authToken;
    }
}

async function postVideoWithProgress(
    params,
    onProgressFunc,
    completeFunc,
    failFunc,
    setIsUploading
) {
    const config = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted);
            onProgressFunc(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: setTokenToHeader(),
        },
    };
    console.log(params);

    axios
        .post(`${HOST}/post-videos/`, params, config)
        .then((res) => {
            console.log('OK');
            completeFunc();

            setIsUploading(false);
        })
        .catch((err) => {
            console.log('fail');
            failFunc(err);
            setIsUploading(false);
        });
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={props.progress} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    style={{ color: 'white' }}
                >{`${props.progress}%`}</Typography>
            </Box>
        </Box>
    );
}
