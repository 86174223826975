import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const VideoPaginationViewer = ({
    handleForwardClick,
    handleBackwardClick,
    currentPage,
    maxPages,
    maxLen,
}) => {
    const start = (currentPage - 1) * 30 + 1;
    const finish = currentPage * 30 > maxLen ? maxLen : currentPage * 30;
    return (
        <div style={{ color: 'white', fontSize: '8pt' }}>
            <span>
                {currentPage > 1 ? (
                    <Button
                        variant="text"
                        style={{ fontSize: '8pt', color: 'white' }}
                        endIcon={<ArrowBackIosIcon />}
                        onClick={handleBackwardClick}
                    >
                        前の30件
                    </Button>
                ) : (
                    <Button
                        variant="text"
                        style={{ fontSize: '8pt', color: 'gray' }}
                        endIcon={<ArrowBackIosIcon />}
                        disabled
                    >
                        前の30件
                    </Button>
                )}
            </span>
            <span
                style={{
                    marginTop: '5px',
                    paddingLeft: '14px',
                    paddingRight: '14px',
                }}
            >
                {start}-{finish}件目を表示中（{currentPage} / {maxPages}）
            </span>
            <span>
                {currentPage < maxPages ? (
                    <Button
                        variant="text"
                        style={{ fontSize: '8pt', color: 'white' }}
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={handleForwardClick}
                    >
                        次の30件
                    </Button>
                ) : (
                    <Button
                        variant="text"
                        style={{ fontSize: '8pt', color: 'grey' }}
                        endIcon={<ArrowForwardIosIcon />}
                        disabled
                    >
                        次の30件
                    </Button>
                )}
            </span>
        </div>
    );
};
