import React from 'react';
import { Button, Modal, Fade, Backdrop, Box } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import { style } from './style';

export const BeforeCalibModal = (props) => {
    const handleClose = () => {
        props.cancelFunc(false);
    };

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.beforeCalib}>
                        <h3 id="transition-modal-title">
                            クリックすると全ての動画の速度・ストライドが再計算されますが、よろしいですか？
                        </h3>

                        <div id="transition-modal-description">
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Button
                                    onClick={props.cancelFunc}
                                    variant="outlined"
                                >
                                    戻る
                                </Button>
                                {props.isLoading ? (
                                    <Button
                                        variant="contained"
                                        disabled
                                        style={{
                                            marginLeft: '20px',
                                        }}
                                    >
                                        はい
                                        <CircularProgress size={20} />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={props.execFunc}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: '20px' }}
                                    >
                                        はい
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
