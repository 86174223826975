import React from 'react';
import './App.css';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
    Menu,
    MenuItem,
    Fade,
    ListItemIcon,
    ListItemText,
    Button,
    Grid,
} from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';
import { AuthContext, AccountContext } from './App';
import './AccountSwitch.css';

export const MemberHeader = () => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isAuthenticated, setIsAuthenticated] = React.useContext(AuthContext);
    const { accountInfo, currentRunner, setCurrentRunner } =
        React.useContext(AccountContext);

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.setItem('access', 'undefined');
        setIsAuthenticated(false);
        history.push(`/login`);
    };
    const handleGoToAccount = () => {
        setAnchorEl(null);
        history.push(`/mypage`);
    };
    const handleAddRunner = () => {
        setAnchorEl(null);
        history.push(`/add-runner`);
    };

    return (
        <div>
            <div id="headerBar">
                <p id="appName">IDATEN-pro</p>
                <p id="mypage">
                    <AccountCircleIcon
                        onClick={handleClick}
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        style={{ color: '#fff', paddingRight: '2px' }}
                    />

                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={handleGoToAccount}>
                            <span style={{ fontSize: '10pt' }}>
                                選択中のランナー: &nbsp;
                                {currentRunner.runner_name}
                            </span>
                        </MenuItem>
                        <MenuItem onClick={handleGoToAccount}>
                            <ListItemIcon style={{ minWidth: '36px' }}>
                                <ContactMailIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>マイページ</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon style={{ minWidth: '36px' }}>
                                <ExitToAppIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>ログアウト</ListItemText>
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon style={{ minWidth: '36px' }}>
                                <GroupIcon fontSize="small" />
                            </ListItemIcon>
                            <RunnerSwitcher
                                runnerList={accountInfo.runners}
                                goAddNewRunner={handleAddRunner}
                                currentRunner={currentRunner}
                                setCurrentRunner={setCurrentRunner}
                                handleClose={handleClose}
                            />
                        </MenuItem>
                    </Menu>
                </p>
            </div>
        </div>
    );
};

const RunnerSwitcher = (props) => {
    const history = useHistory();

    const { runnerList, setCurrentRunner, goAddNewRunner } = props;
    const handleChangeRunner = (runner) => () => {
        setCurrentRunner(runner);
        localStorage.setItem('currentRunner', String(runner.id));
        history.push('/');
        props.handleClose();
    };
    return (
        <div className="menu">
            <label htmlFor="menu_bar01">ランナーの切り替え</label>
            <input type="checkbox" id="menu_bar01" />
            <ul id="links01">
                {runnerList.map((runner, idx) => {
                    return (
                        <li key={idx} onClick={handleChangeRunner(runner)}>
                            <span id="name_icon">
                                <span>{runner.runner_name.split('')[0]}</span>
                            </span>
                            &nbsp;
                            {runner.runner_name}
                        </li>
                    );
                })}
                <li
                    onClick={goAddNewRunner}
                    style={{ fontSize: '10pt', display: 'flex' }}
                >
                    <GroupAddIcon size="small" />
                    <span style={{ marginLeft: '3px' }}>ランナーを追加</span>
                </li>
            </ul>
        </div>
    );
};
