import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { formatDate } from '../videoListPage/VideoListPage';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';

const Input = styled(MuiInput)`
    width: 42px;
`;

export const ScoreView = ({ scores, accountInfo, isMock }) => {
    const [thresh, setThresh] = React.useState(7);
    const [addition, setAddition] = React.useState('pitch');

    const handleSliderChange = (e) => {
        setThresh(e.target.value);
    };

    const handleInputChange = (event) => {
        setThresh(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (thresh < 0) {
            setThresh(0);
        } else if (thresh > 100) {
            setThresh(13);
        }
    };

    const handleRadioChange = (e) => {
        setAddition(e.target.value);
    };

    const bestBoutData = getDailyBestBouts(scores, accountInfo.height, thresh);
    console.log(bestBoutData);
    const domain = crt_domain(bestBoutData, 'speed');
    const pitchDomain = crt_domain(bestBoutData, 'pitch');
    const strideDomain = crt_domain(bestBoutData, 'stride');
    console.log(domain);
    return (
        <div style={{ marginTop: '15px' }}>
            {scores.length > 0 && domain[0] !== 1200 ? (
                <>
                    <div
                        style={{
                            color: '#EEEEEE',
                            fontSize: '12pt',
                            fontWeight: 'bold',
                            padding: '6px',
                        }}
                    >
                        日々の推移
                    </div>
                    {isMock ? (
                        <div
                            style={{
                                color: 'white',
                                marginTop: '10px',
                                fontSize: '8pt',
                            }}
                        >
                            動画を送るとこのように日々の調子がグラフ化されます
                        </div>
                    ) : null}

                    <div
                        style={{
                            textAlign: 'center',
                            padding: '5px',
                            overflowX: 'scroll',
                        }}
                    >
                        <LineChart
                            className="rechars-surface"
                            data={bestBoutData}
                            width={window.parent.screen.width * 1.2}
                            height={window.parent.screen.width * 0.7}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                //allowDuplicatedCategory={false}
                                tick={{ fontSize: 8 }}
                            />
                            <YAxis
                                dataKey="speed"
                                domain={domain}
                                tick={{ fontSize: 10, fontWeight: 'bold' }}
                                width={30}
                                yAxisId="left"
                            />

                            <Tooltip
                                labelStyle={{ color: 'green' }}
                                labelFormatter={function (label) {
                                    return `${label}`;
                                }}
                            />
                            <Legend verticalAlign="top" height={26} />

                            <Line
                                dot={false}
                                dataKey="speed"
                                stroke="#66cdaa"
                                strokeWidth={2}
                                yAxisId="left"
                            />
                            {addition === 'pitch' ? (
                                <Line
                                    dot={false}
                                    dataKey="pitch"
                                    stroke="#a0d8ef"
                                    strokeWidth={3}
                                    yAxisId="right"
                                    strokeDasharray="5 5"
                                />
                            ) : (
                                <Line
                                    dot={false}
                                    dataKey="stride"
                                    stroke="#ffa500"
                                    strokeWidth={2}
                                    yAxisId="right"
                                    strokeDasharray="5 5"
                                />
                            )}

                            {addition === 'pitch' ? (
                                <YAxis
                                    dataKey="pitch"
                                    domain={pitchDomain}
                                    tick={{ fontSize: 0, fontWeight: 'bold' }}
                                    width={20}
                                    yAxisId="right"
                                    orientation="right"
                                />
                            ) : (
                                <YAxis
                                    dataKey="stride"
                                    domain={strideDomain}
                                    tick={{ fontSize: 10, fontWeight: 'bold' }}
                                    width={30}
                                    yAxisId="right"
                                    orientation="right"
                                />
                            )}
                        </LineChart>
                    </div>

                    <div>
                        <div
                            style={{
                                marginTop: '10px',
                                color: '#EEEEEE',
                                fontSize: '8pt',
                                marginLeft: '70%',
                            }}
                        >
                            Filter by Speed
                        </div>
                        <Grid container spacing={1} alignItems="center">
                            <Grid xs={2} item>
                                {/* <Typography
                                style={{ color: 'white', fontSize: '10pt' }}
                            ></Typography> */}
                                <DirectionsRunIcon
                                    style={{ color: 'white', fontSize: '28pt' }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Slider
                                    defaultValue={7}
                                    onChange={handleSliderChange}
                                    value={
                                        typeof thresh === 'number' ? thresh : 0
                                    }
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={13}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    style={{ color: 'white' }}
                                    value={thresh}
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        max: 13,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                                <span
                                    style={{
                                        color: '#EEEEEE',
                                        fontSize: '8pt',
                                    }}
                                >
                                    m/s
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <div style={{ color: 'white' }}>
                            サブ項目を切り替える
                        </div>

                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                defaultValue="female"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="pitch"
                                    style={{
                                        color: 'white',
                                    }}
                                    control={
                                        <Radio style={{ color: '#a0d8ef' }} />
                                    }
                                    label="pitch"
                                    onChange={handleRadioChange}
                                />
                                <FormControlLabel
                                    value="stride"
                                    style={{ color: 'white' }}
                                    control={
                                        <Radio style={{ color: '#a0d8ef' }} />
                                    }
                                    label="stride"
                                    onChange={handleRadioChange}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </>
            ) : null}
        </div>
    );
};

function getDailyBestBouts(scoreList, height, thresh) {
    // scoreListを入力とし
    //[{date:2022-02-03, pitch:5, stride:3 }, ... ]みたいな辞書を作る}
    let retList = [];
    let dailyObj = {};
    for (var score of scoreList) {
        // あとあとscore.video.isGuestに基づく分岐があり得るのでmapではなくfor文でかく
        // まずはこの段階で、日ごとにまとめたリストを作る
        const date = formatDate(score.created_at)[0];
        const obj = {
            date: date,
            speed: Number(score.speed) * height,
            pitch: Number(score.pitch).toFixed(3),
            stride: (Number(score.stride) * height).toFixed(3),
        };
        if (obj.speed < thresh) {
            continue;
        }
        if (date in dailyObj) {
            // 重複があった場合はここで入れ替えの決戦を行う
            if (dailyObj[date].speed > obj.speed) {
                //スピードをキーにしてる
                continue;
            } else {
                dailyObj[date] = obj;
            }
        } else {
            dailyObj[date] = obj;
            continue;
        }
    }

    // 続いてdailyObjをリストにする
    Object.values(dailyObj).map((dailyBestBout) => {
        retList.push({
            ...dailyBestBout,
            speed: dailyBestBout.speed.toFixed(2),
        });
    });
    return retList;
}

function crt_domain(data, key) {
    if (!data) {
        return;
    }
    let max = 0;
    let min = 2000;
    const ary = data.map((row) => {
        if (Number(row[key]) > max) {
            max = Number(row[key]);
        }
        if (Number(row[key]) < min) {
            min = Number(row[key]);
        }
        return Number(row[key]);
    });
    if (key === 'speed') {
        return [
            Number((min * 0.6).toFixed(1)),
            Number((max * 1.05).toFixed(1)),
        ];
    }
    if (key !== 'speed') {
        return [Number((min * 0.8).toFixed(1)), Number((max * 1.7).toFixed(1))];
    }
}
