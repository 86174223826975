import React from 'react';
import { Button, Modal, Fade, Backdrop, Box } from '@mui/material';
import { style } from './style';

export const AnalysisErrorModal = (props) => {
    const handleClose = () => {
        props.setIsModalOpen(false);
    };

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.AnalysisErrorPaper}>
                        <div id="transition-modal-description">
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    解析失敗の原因には下記が考えられます。
                                </span>
                                <ul
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    <li>スロー撮影ではない</li>
                                    <li>追い撮りしている</li>
                                    <li>走者の体が隠れる瞬間がある</li>
                                    <li>撮影環境が暗い</li>
                                    <li>たくさんの人が写り込んでいる。</li>
                                </ul>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>
                                        精度が低くなる原因には下記が考えられます。
                                    </span>{' '}
                                    <ul
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <li>カメラが固定されていない</li>
                                        <li>カメラが傾いている</li>
                                    </ul>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                    >
                                        閉じる
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
