import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const ListSwitcher = ({ isListedGrid, setIsListedGrid }) => {
    return (
        <div style={{ display: 'flex', marginTop: '16px', marginLeft: '6px' }}>
            <span
                style={{ fontSize: '8pt', marginRight: '12px', color: 'white' }}
            >
                表示切り替え
            </span>
            {isListedGrid ? (
                <>
                    <FormatListBulletedIcon
                        size="small"
                        style={{ color: '#aaaaaa' }}
                        onClick={() => setIsListedGrid(false)}
                    />
                    ｜
                    <AppsIcon
                        size="small"
                        style={{ color: '#99CCFF' }}
                        onClick={() => setIsListedGrid(true)}
                    />
                </>
            ) : (
                <>
                    <FormatListBulletedIcon
                        style={{ color: '#99CCFF' }}
                        onClick={() => setIsListedGrid(false)}
                    />
                    ｜
                    <AppsIcon
                        style={{ color: '#aaaaaa' }}
                        onClick={() => setIsListedGrid(true)}
                    />
                </>
            )}
        </div>
    );
};
