import React from 'react';
import { useHistory } from 'react-router-dom';
import { VideoPaginationViewer } from './components/VideoPaginationViewer';
import { SingleVideoCard } from './components/SingleVideoCard';
import { Grid } from '@material-ui/core';
import { AccountContext } from '../../../App';
import { SearchBar } from '../../../utils/SearchBar';
import { PageTitle } from '../../common/PageTitle';
import { ListSwitcher } from './components/ListSwitcher';
import { GuestControl } from './components/GuestControl';

import CircularProgress from '@mui/material/CircularProgress';
import './VideoList.css';
import { useGetVideoByRunner } from '../../../hooks/useGetVideoByRunner';

export const VideoListPage = () => {
    const history = useHistory();
    const { currentRunner } = React.useContext(AccountContext);
    const [videoFromApi, setVideoFromApi] = React.useState([]); //引っ張ってきた全件数
    const [searchWord, setSearchWord] = React.useState('');
    const [videoToShow, setVideoToShow] = React.useState([]); //抱えてる全件数
    const [isListedGrid, setIsListedGrid] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [videoShown, setVideoShown] = React.useState([]);

    const { videos, isVideoLoading } = useGetVideoByRunner(currentRunner.id);

    const maxPages = parseInt((videoToShow.length - 1) / 30 + 1);

    React.useEffect(() => {
        setVideoFromApi(sortVideoByDate(videos));
        setVideoToShow(sortVideoByDate(videos));
    }, [videos]);

    React.useEffect(() => {
        if (videoToShow.length > 0) {
            console.log(videoToShow);
            setCurrentPage(1);
            setVideoShown(videoToShow.slice(0, 30));
        }
    }, [videoToShow]);

    const changeVideoId = (video) => () => {
        if (video.id) {
            history.push(`/result/${video.id}`);
        }
    };

    const handleForwardClick = () => {
        if (maxPages > currentPage) {
            const maxLen = videoToShow.length;
            const start = parseInt(currentPage * 30);
            const finish =
                (currentPage + 1) * 30 > maxLen
                    ? maxLen
                    : parseInt(currentPage * 30 + 30);
            setVideoShown(videoToShow.slice(start, finish));
            setCurrentPage(currentPage + 1);
        }
    };

    const handleBackwardClick = () => {
        if (currentPage > 1) {
            const start = parseInt((currentPage - 2) * 30);
            const finish = parseInt((currentPage - 1) * 30);
            setVideoShown(videoToShow.slice(start, finish));
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSearch = () => {
        var data = videoFromApi;
        var result = data.filter(function (video) {
            return video.memo.indexOf(searchWord) !== -1;
        });
        setVideoToShow(result);
    };

    const handleChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <PageTitle title={'ライブラリ（新しい順に表示）'} />
            <Grid container>
                <Grid item xs={7}>
                    <SearchBar
                        label="メモから動画を検索"
                        placeholder="（例）流し"
                        onIconClick={handleSearch}
                        func={handleChangeSearchWord}
                    />
                </Grid>
                <Grid item xs={5}>
                    <ListSwitcher
                        isListedGrid={isListedGrid}
                        setIsListedGrid={setIsListedGrid}
                    />
                </Grid>
                {/* <GuestControl /> */}
            </Grid>
            {videoToShow.length > 0 ? (
                <VideoPaginationViewer
                    handleForwardClick={handleForwardClick}
                    handleBackwardClick={handleBackwardClick}
                    currentPage={currentPage}
                    maxPages={maxPages}
                    maxLen={videoToShow.length}
                />
            ) : (
                <></>
            )}
            <div
                style={{
                    margin: '3px',
                    overflowY: 'scroll',
                    height: `${window.parent.screen.height - 250}px`,
                    border: '2px solid #001e43',
                }}
            >
                {videoToShow && videoToShow.length > 0 ? (
                    isListedGrid ? ( //grid形式での表示
                        <GridView
                            video={videoShown}
                            handleClick={changeVideoId}
                            currentSelected={null}
                        />
                    ) : (
                        // list形式での表示
                        videoShown.map((vObj, idx) => {
                            return (
                                <SingleVideoCard
                                    key={idx}
                                    video={vObj}
                                    idx={idx}
                                    handleClick={changeVideoId}
                                />
                            );
                        })
                    )
                ) : (
                    <div>
                        {isVideoLoading ? (
                            <>
                                <CircularProgress color="success" />
                                <div style={{ color: 'white' }}>
                                    ビデオをロード中
                                </div>
                            </>
                        ) : (
                            <div style={{ color: 'white' }}>
                                動画がありません
                            </div>
                        )}
                    </div>
                )}
                <p>{''}</p>
                {/* スクロール幅を増やすために */}
                <p>{''}</p>
            </div>
        </div>
    );
};

export function sortVideoByDate(videoList) {
    return videoList.sort(function (a, b) {
        if (a.uploaded_date < b.uploaded_date) return 1;
        if (a.uploaded_date > b.uploaded_date) return -1;
        return 0;
    });
}

export const GridView = ({ video, handleClick, currentSelected }) => {
    const videoNum = video.length;
    const maxColumns = 3; // 一旦列数はハードコーディング
    const maxRows = parseInt((videoNum - 1) / maxColumns + 1);

    const arr = [...Array(maxRows)].map((_, i) => i);
    const arrCol = [...Array(maxColumns)].map((_, i) => i);
    const mockVideo = { thumbnail: '' }; //足りない時のために補う
    if (videoNum > 0) {
        return (
            <div>
                {arr.map((row, rowIdx) => {
                    // row + 1行目を描画
                    return (
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            style={{ marginBottom: '16px' }}
                        >
                            {arrCol.map((_, colIdx) => {
                                const videoIdx = rowIdx * 3 + colIdx;
                                const videoShown =
                                    video.length >= parseInt(videoIdx + 1)
                                        ? video[videoIdx]
                                        : mockVideo;
                                return (
                                    <Grid xs={4}>
                                        <VideoCellView
                                            video={videoShown}
                                            handleClick={handleClick}
                                            currentSelected={currentSelected}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                })}
            </div>
        );
    } else {
        return (
            <div style={{ color: 'white', textAlign: 'center' }}>
                動画がありません
            </div>
        );
    }
};

const VideoCellView = (props) => {
    if (
        !!props.currentSelected &&
        props.currentSelected.id === props.video.id
    ) {
        console.log(props.currentSelected);
        return (
            <div style={{ border: '4px solid #66FF99' }}>
                <img
                    id="blurred"
                    alt=""
                    onClick={props.handleClick(props.video)}
                    width="94%"
                    src={`https://pidaten.s3.amazonaws.com/static/${props.video.thumbnail}`}
                ></img>
            </div>
        );
    }
    return (
        <div>
            <img
                id="blurred"
                alt=""
                onClick={props.handleClick(props.video)}
                width="90%"
                src={`https://pidaten.s3.amazonaws.com/static/${props.video.thumbnail}`}
            ></img>
        </div>
    );
};

export function formatDate(string) {
    let dateJS = new Date(string);
    // どうやらこの書き方をするとフロントが描画されている場所の時刻になるっぽい！
    let nengappi =
        dateJS.getFullYear() +
        '/' +
        (dateJS.getMonth() + 1) +
        '/' +
        dateJS.getDate() +
        '';

    let jikoku = dateJS.getHours() + ':' + addZero(dateJS.getMinutes());

    return [nengappi, jikoku];
}

function addZero(number) {
    if (number < 10) {
        return '0' + String(number);
    } else return String(number);
}
