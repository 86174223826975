import React from 'react';
import './App.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { VideoUploadPage } from './components/pages/UploadPage/VideoUploadPage';
import { VideoListPage } from './components/pages/videoListPage/VideoListPage';
import { ResultPage } from './components/pages/ResultPage/ResultPage';
import { LoginPage } from './components/LoginPage';
import { LogOutPage } from './components/LogoutPage';
import { RegisterPage } from './components/pages/RegisterPage/RegisterPage';
import { UserAccountPage } from './components/pages/AccountPage/UserAccountPage';
import { MemberHomePage } from './components/pages/MemberHome/MemberHomePage';
import { AnalyzePage } from './components/pages/AnalyzePage/AnalyzePage';
import { AdminPage } from './components/pages/AdminPage';
import {
    HeaderComponent,
    FooterComponent,
} from './components/templates/PageTemplate';
import { MemberHeader } from './MemberHeader';
import { getAccountInfo } from './v1apiRequest';
import { UploadNotification } from './components/Notifications';
import { AddRunnerPage } from './components/pages/AddRunnerPage';

export const VideoContext = React.createContext('');
export const AuthContext = React.createContext('');
export const AccountContext = React.createContext('');

function App() {
    const [videoList, setVideoList] = React.useState([]);
    const [videoSentStatus, setVideoSentStatus] = React.useState({
        open: false,
        status: 'yet',
    });
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [accountInfo, setAccountInfo] = React.useState({});
    const [currentRunner, setCurrentRunner] = React.useState({});

    React.useEffect(() => {
        var _videoList = localStorage.getItem('videos');
        if (_videoList) {
            setVideoList(convertStringToList(_videoList));
        } else {
            setVideoList([]);
        }
        var authToken = localStorage.getItem('access');
        if (authToken !== 'undefined' && authToken) {
            setIsAuthenticated(true);
            return;
        }
        if (authToken === 'undefined') {
            setIsAuthenticated(false);
            return;
        }
    }, []);

    // isAuthenticatedがTrueになった瞬間にアカウント情報を取りに行く処理を追加
    React.useEffect(() => {
        if (isAuthenticated) {
            const curRunner = localStorage.getItem('currentRunner');
            getAccountInfo()
                .then((res) => {
                    console.log('account=', res.data);
                    setAccountInfo(res.data);
                    // 続いて、localStorageを参照して適切なrunnerをセット

                    if (curRunner && curRunner.split('').length > 3) {
                        // 要は保存されてたら、ということ
                        const prevRunner = searchRunnerById(
                            curRunner,
                            res.data.runners
                        );
                        console.log('prevRunner=', prevRunner);

                        setCurrentRunner(prevRunner);
                    } else {
                        setCurrentRunner(res.data.runners[0]);
                        console.log(res.data.runners[0]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [isAuthenticated]);
    // localStorageにトークンがあったらisAuthenticatedをtrueにすべき（そうしないとリロードの度にログイン必要）
    return (
        <BrowserRouter>
            <AuthContext.Provider value={[isAuthenticated, setIsAuthenticated]}>
                {isAuthenticated ? (
                    <AccountContext.Provider
                        value={{
                            accountInfo,
                            setAccountInfo,

                            currentRunner,
                            setCurrentRunner,
                        }}
                    >
                        <VideoContext.Provider
                            value={{
                                videoList,
                                setVideoList,
                                videoSentStatus,
                                setVideoSentStatus,
                            }}
                        >
                            <MemberHeader />
                            <UploadNotification status={videoSentStatus} />
                            <main className="main-style">
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        component={MemberHomePage}
                                    />
                                    <Route
                                        exact
                                        path="/login"
                                        component={MemberHomePage}
                                    />
                                    <Route
                                        path="/upload"
                                        component={VideoUploadPage}
                                    />
                                    <Route
                                        path="/analyze"
                                        component={AnalyzePage}
                                    />
                                    <Route
                                        path="/result/:video_id"
                                        component={ResultPage}
                                    />
                                    <Route
                                        path="/videolist"
                                        component={VideoListPage}
                                    />

                                    <Route
                                        path="/mypage"
                                        component={UserAccountPage}
                                    />
                                    <Route
                                        path="/add-runner"
                                        component={AddRunnerPage}
                                    />

                                    <Route
                                        path="/admin"
                                        component={AdminPage}
                                    />

                                    <Route
                                        path="/logout"
                                        component={LogOutPage}
                                    />
                                </Switch>
                            </main>
                            <footer>
                                <FooterComponent />
                            </footer>
                        </VideoContext.Provider>
                    </AccountContext.Provider>
                ) : (
                    // authenticateされてない場合はこっちへ
                    <div>
                        <HeaderComponent />

                        <main>
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    component={LoginPage}
                                />
                                <Route
                                    path="/register"
                                    component={RegisterPage}
                                />
                                <Route path="/" component={LoginPage} />
                            </Switch>
                        </main>
                    </div>
                )}
            </AuthContext.Provider>
        </BrowserRouter>
    );
}

export default App;

export function convertStringToList(stringData) {
    // localStorageに溜まった文字列（元はリスト）をリスト化する作業
    if (stringData) {
        let strList = stringData.split(',');
        return strList;
    } else {
        return null;
    }
}

function searchRunnerById(rId, rList) {
    for (const runner of rList) {
        if (String(runner.id) === String(rId)) {
            return runner;
        }
        continue;
    }
    return false;
}
