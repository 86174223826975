import React from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SearchByTag = ({ phase, handleChangeTag }) => {
    return (
        <Box sx={{ minWidth: 100, marginLeft: '10px' }}>
            <FormControl style={{ width: '90%' }} variant="outlined">
                <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: '#b7dbff' }}
                >
                    Phase
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phase}
                    label="phase"
                    onChange={handleChangeTag}
                    style={{
                        backgroundColor: '#222222',
                        color: '#b7dbff',
                    }}
                    MenuProps={{
                        sx: {
                            '&& .Mui-selected': {
                                backgroundColor: '#a1d8e6',
                            },
                        },
                    }}
                >
                    <MenuItem value={'start'}>スタート</MenuItem>
                    <MenuItem value={'accelerating'}>加速走</MenuItem>
                    <MenuItem value={'middle'}>中間走</MenuItem>
                    <MenuItem value={'None'}>指定しない</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};
