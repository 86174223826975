import React from 'react';

import {
    Grid,
    InputAdornment,
    Fade,
    TextField,
    Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../../../App';
import { VideoCalibModal } from './VideoCalibModal';
import { VideoConfirmModal } from './VideoConfirmModal';
import { BeforeCalibModal } from './BeforeCalibModal';
import { calibRunnerHeight } from '../../../../v1apiRequest';

export const CalibrationFlow = ({ activeStep, setActiveStep }) => {
    const history = useHistory();

    const { currentRunner } = React.useContext(AccountContext);
    const [trueSpeed, setTrueSpeed] = React.useState('');
    const [isModalShown, setIsModalShown] = React.useState(false);
    const [beforeCalibModal, setBeforeCalibModal] = React.useState(false);
    const [calibVideo, setCalibVideo] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [confirmModalShow, setConfirmModalShow] = React.useState(false);
    const handleSetVideo = (video) => () => {
        if (video) {
            console.log(video);
            setCalibVideo(video);
            setIsModalShown(false);
            setConfirmModalShow(true);
        }
    };

    const handleCancel = () => {
        setConfirmModalShow(false);
        setCalibVideo({});
    };

    const handleDecide = () => {
        setConfirmModalShow(false);
        setActiveStep(1);
    };

    const handleClick = () => {
        setBeforeCalibModal(true);
    };

    const handleSendClick = () => {
        setIsLoading(true);

        const params = {
            id: currentRunner.id,
            true_speed: trueSpeed,
            speed_per_cm: calibVideo.score.speed,
        };
        calibRunnerHeight(params)
            .then((_) => {
                alert('OK');
                setIsLoading(false);
                setBeforeCalibModal(false);
                history.push('/');
            })
            .catch((res) => {
                console.log(res.data);
                setIsLoading(false);
                setBeforeCalibModal(false);
            });
    };

    console.log(trueSpeed);

    return (
        <div style={{ marginTop: '18px' }}>
            {confirmModalShow ? (
                <VideoConfirmModal
                    isModalOpen={confirmModalShow}
                    setIsModalOpen={setConfirmModalShow}
                    video={calibVideo}
                    execFunc={handleDecide}
                    cancelFunc={handleCancel}
                />
            ) : null}

            {beforeCalibModal ? (
                <BeforeCalibModal
                    isModalOpen={beforeCalibModal}
                    execFunc={handleSendClick}
                    cancelFunc={() => setBeforeCalibModal(false)}
                    isLoading={isLoading}
                />
            ) : null}

            {isModalShown ? (
                <VideoCalibModal
                    isModalOpen={isModalShown}
                    setIsModalOpen={setIsModalShown}
                    handleSetVideo={handleSetVideo}
                />
            ) : null}

            {activeStep >= 1 ? (
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => setIsModalShown(true)}
                    style={{ border: '1px solid #6c9db2', color: 'white' }}
                >
                    調整用動画を変更する
                </Button>
            ) : (
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => setIsModalShown(true)}
                    style={{ backgroundColor: '#6c9bd2', color: 'white' }}
                >
                    ライブラリから動画を選択
                </Button>
            )}

            {activeStep >= 1 ? (
                <TrueSpeedInput
                    video={calibVideo}
                    setTrueSpeed={setTrueSpeed}
                />
            ) : null}
            {activeStep >= 1 && !isNaN(trueSpeed) && trueSpeed > 0 ? (
                <div style={{ paddingTop: '20px' }}>
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        style={{
                            color: 'white',
                            backgroundColor: '#6c9bd2',
                        }}
                    >
                        この情報で精度を調整する
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export const TrueSpeedInput = ({ video, setTrueSpeed }) => {
    return (
        <div>
            <Grid container style={{ marginTop: '20px', paddingTop: '10px' }}>
                <Grid item xs={4}>
                    <div>
                        <img
                            id="blurred"
                            alt=""
                            width="80%"
                            src={`https://pidaten.s3.amazonaws.com/static/${video.thumbnail}`}
                        ></img>
                    </div>
                </Grid>

                <Grid item xs={8}>
                    <div style={{ textAlign: 'left' }}>
                        <div
                            style={{
                                fontSize: '11pt',
                                display: 'flex',
                                paddingLeft: '10px',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            ←左の動画の実際の速度を入力します。
                        </div>
                        <TrueSpeedField setTrueSpeed={setTrueSpeed} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const TrueSpeedField = ({ setTrueSpeed }) => {
    const hankakuKyosei = '半角数字で入力してください';
    const [speed, setSpeed] = React.useState({});
    const [validationMessage, setValidationMessage] = React.useState('');
    const handleChange = (e) => {
        if (isNaN(e.target.value)) {
            setValidationMessage(hankakuKyosei);
            return;
        }
        if (!isNaN(e.target.value) && e.target.value > 18) {
            setValidationMessage(
                '速度が大きいようです。入力が正しいか再度ご確認ください。'
            );
            setTrueSpeed(e.target.value);
            return;
        }
        if (!isNaN(e.target.value) && e.target.value < 1) {
            setValidationMessage(
                '速度が小さいようです。入力は正しいでしょうか？'
            );
            setTrueSpeed(e.target.value);
            return;
        }
        setSpeed(e.target.value);
        setTrueSpeed(e.target.value);

        console.log(e.target.value);
        setValidationMessage('');
    };

    return (
        <div style={{ marginLeft: '10px', padding: '10px' }}>
            <TextField
                label={
                    <span
                        style={{
                            fontSize: '0.88rem',
                            color: '#99CCFF',
                        }}
                    >
                        速度（秒速）
                    </span>
                }
                onChange={handleChange}
                InputProps={{
                    style: {
                        paddingRight: '1px',
                        color: 'white',
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <span style={{ color: '#99CCFF' }}>m/秒</span>
                        </InputAdornment>
                    ),
                }}
            />

            {validationMessage === hankakuKyosei ? (
                <div style={{ color: '#ea553a' }}>{validationMessage} </div>
            ) : (
                <div style={{ color: '#fcc800' }}>{validationMessage} </div>
            )}
        </div>
    );
};
