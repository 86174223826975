import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Card, Modal, Fade, Backdrop, TextField } from '@material-ui/core';
import { AccountContext } from '../../../App';
import { TabInfo } from '../ResultPage/TabInfo';

import { putAccountInfo, putRunnerInfo } from '../../../v1apiRequest';
import { PageTitle } from '../../common/PageTitle';
import { HeightSelecter } from '../../../utils/HeightSelecter';
import { CalibrationStepViewer } from './components/CalibrationStepViewer';
import { CalibrationFlow } from './components/CalibrationFlow';

export const CalibrationView = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    return (
        <>
            <Card
                style={{
                    backgroundColor: '#696969',
                    padding: '10px',
                    fontSize: '11pt',
                    color: '#f0f6da',
                    textAlign: 'left',
                    fontWeight: 'bold',
                }}
            >
                このページでは、アプリ外で測定された実際の速度をもとに精度の調整を行えます。
            </Card>
            <CalibrationStepViewer activeStep={activeStep} />
            <CalibrationFlow
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
        </>
    );
};
