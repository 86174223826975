import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
// https://recharts.org/en-US/api/XAxis ここに意外と載ってる
import '../../../componentStyle.css';

import { Card } from '@mui/material';
import { DoubleSpeedCurve } from './DoubleSpeedCurve';

export const ComparisonGraphs = ({
    beforeInfo,
    height,
    counterVideo,
    currentVideo,
}) => {
    console.log(beforeInfo);
    const counterInfo = counterVideo.score;
    if (!counterInfo.pitch) {
        console.log('エラー');
        return <></>;
    }
    const beforeScore = {
        speed: beforeInfo.speed * height,
        stride: beforeInfo.stride * height,
        pitch: beforeInfo.pitch,
    };

    const counterScore = {
        speed: counterInfo.speed * height,
        stride: counterInfo.stride * height,
        pitch: counterInfo.pitch,
    };

    const pitchData = {
        name: 'ピッチ',
        data: [
            { category: '  Left', value: Number(beforeScore.pitch).toFixed(3) },
            {
                category: '  Right',
                value: Number(counterScore.pitch).toFixed(3),
            },
        ],
    };
    const strideData = {
        name: 'ストライド（m）',
        data: [
            {
                category: '  Left',
                value: Number(beforeScore.stride.toFixed(3)),
            },
            {
                category: '  Right',
                value: Number(counterScore.stride.toFixed(3)),
            },
        ],
    };
    const speedData = {
        name: 'スピード（m/s）',
        data: [
            {
                category: 'Left',
                value: Number(beforeScore.speed.toFixed(3)),
            },
            {
                category: '  Right',
                value: Number(counterScore.speed.toFixed(3)),
            },
        ],
    };

    const speedDom = crt_domain(speedData.data);
    const pitchDom = crt_domain(pitchData.data);
    const strideDom = crt_domain(strideData.data);
    const dotDesign = { stroke: 'red', strokeWidth: 1 };

    if (
        !!currentVideo.id &&
        !!counterVideo.id &&
        currentVideo.phase === 'start' &&
        counterVideo.phase === 'start'
    )
        return (
            <DoubleSpeedCurve
                origin={currentVideo}
                counter={counterVideo}
                height={height}
            />
        );

    return (
        <div>
            <ComparisonGraph
                strokeColor={'#66cdaa'}
                targetData={speedData}
                dataDomain={speedDom}
                dotDesign={dotDesign}
            />
            <br />
            <ComparisonGraph
                strokeColor={'#00bfff'}
                targetData={pitchData}
                dataDomain={pitchDom}
            />

            <br />
            <br />
            <ComparisonGraph
                strokeColor={'#ffa500'}
                targetData={strideData}
                dataDomain={strideDom}
            />
            <br />
        </div>
    );
};

export function crt_domain(data) {
    // 大きい方と小さい方の10% +と -を上限と下限にする
    let numbers = data.map((d) => d.value);
    numbers.sort(function (a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });
    console.log(numbers);
    return [
        Number((numbers[0] * 0.95).toFixed(1)),
        Number((numbers[1] * 1.05).toFixed(1)),
    ];
}

export const ComparisonGraph = ({
    dataDomain,
    targetData,
    strokeColor,
    dotDesign,
}) => {
    return (
        <Card>
            <LineChart
                width={window.parent.screen.width * 0.9}
                height={window.parent.screen.width * 0.5}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="category"
                    type="category"
                    allowDuplicatedCategory={false}
                    padding={{ left: 40, right: 40 }}
                    tick={{ fontSize: 16, fontWeight: 'bold' }}
                />
                <YAxis
                    dataKey="value"
                    domain={dataDomain}
                    tick={{ fontSize: 12, fontWeight: 'bold' }}
                    width={40}
                />
                <Tooltip />
                <Legend verticalAlign="top" height={26} />

                <Line
                    dataKey="value"
                    data={targetData.data}
                    name={targetData.name}
                    key={targetData.name}
                    stroke={strokeColor}
                    strokeWidth={3}
                    dotDesign={dotDesign}
                />
            </LineChart>
        </Card>
    );
};
