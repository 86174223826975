import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Button,
    InputAdornment,
    IconButton,
    Input,
    TextField,
    InputLabel,
    FormControl,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { sendLoginInfo } from '../v1apiRequest';
import { AuthContext } from '../App';

export const LoginPage = (props) => {
    const history = useHistory();
    const [accountFormInfo, setAccountFormInfo] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });
    const [isAuthenticated, setIsAuthenticated] = React.useContext(AuthContext);
    const [isUploading, setIsUploading] = React.useState(false);

    const handleEmailChange = (e) => {
        let _info = { ...accountFormInfo };
        _info['email'] = e.target.value;
        setAccountFormInfo(_info);
    };
    const handleSendClick = () => {
        setIsUploading(true);
        sendLoginInfo(accountFormInfo)
            .then((res) => {
                localStorage.setItem('access', res.data.token);
                setIsAuthenticated(true);
                setIsUploading(false);

                alert('OK');
            })
            .catch((res) => {
                console.log(res);
                console.log(res.message);
                if (res.message == 'Network Error') {
                    alert('申し訳ありません。サーバーエラーです');
                } else if (res.message.indexOf('400')) {
                    alert('認証情報が正しくありません');
                }
                //console.log(res.response.data);
                //console.log(res.response.data.detail);
                setIsUploading(false);
            });
    };

    const handleClickShowPassword = () => {
        setAccountFormInfo({
            ...accountFormInfo,
            showPassword: !accountFormInfo.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (e) => {
        let _info = { ...accountFormInfo };
        _info['password'] = e.target.value;
        setAccountFormInfo(_info);
    };

    const gotoRegister = () => {
        history.push('/register');
    };

    return (
        <div style={{ textAlign: 'center' }}>
            ログインページです
            <div>
                <TextField
                    style={{ marginTop: '20px', width: '70%' }}
                    value={accountFormInfo.email}
                    label="email"
                    onChange={handleEmailChange}
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <FormControl style={{ width: '70%' }}>
                    <InputLabel htmlFor="standard-adornment-password">
                        パスワード
                    </InputLabel>
                    <Input
                        id="standard-adornment-password"
                        value={accountFormInfo.password}
                        label="パスワード"
                        type={
                            accountFormInfo.showPassword ? 'text' : 'password'
                        }
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {accountFormInfo.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            {isUploading ? (
                <Button
                    variant="contained"
                    disabled
                    style={{ marginTop: '30px' }}
                >
                    送信 <CircularProgress size={20} />
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '30px' }}
                    onClick={handleSendClick}
                >
                    送信
                </Button>
            )}
            <div style={{ marginTop: '30px' }}>
                <Typography style={{ fontSize: '9pt' }}>
                    アカウント未発行の方は
                    <span className="textlink" onClick={gotoRegister}>
                        新規登録
                    </span>
                    へ
                </Typography>
            </div>
        </div>
    );
};
