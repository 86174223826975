import React from 'react';

export const TagViewer = ({ tag, marginLeft }) => {
    if (!tag || tag === -1) return <></>;
    const renderTagIndex = (char) => {
        if (char === 'start')
            return (
                <span style={{ fontSize: '8pt', color: '#f0f8ff' }}>
                    スタート
                </span>
            );
        if (char === 'accelerating')
            return (
                <span style={{ fontSize: '8pt', color: '#f0f8ff' }}>
                    加速走
                </span>
            );
        if (char === 100)
            return (
                <span style={{ fontSize: '8pt', color: '#ffc0cb' }}>
                    全力走
                </span>
            );
        if (char === 80)
            return (
                <span style={{ fontSize: '8pt', color: '#87cefa' }}>
                    速い流し
                </span>
            );
        if (char === 60)
            return (
                <span style={{ fontSize: '8pt', color: '#87cefa' }}>流し</span>
            );

        return (
            <span style={{ fontSize: '8pt', color: '#f0f8ff' }}>中間疾走</span>
        );
    };

    return marginLeft ? (
        <div
            style={{
                borderRadius: '3px',
                border: 'solid 1px #d3d6dd',
                maxWidth: '45px',
                padding: '1px',
                backgroundColor: '#808080',
                textAlign: 'center',
                marginLeft: marginLeft,
            }}
        >
            {renderTagIndex(tag)}
        </div>
    ) : (
        <div
            style={{
                borderRadius: '3px',
                border: 'solid 1px #d3d6dd',
                maxWidth: '45px',
                padding: '1px',
                backgroundColor: '#808080',
                textAlign: 'center',
            }}
        >
            {renderTagIndex(tag)}
        </div>
    );
};
