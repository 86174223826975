import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AccountContext } from '../../App';

import {
    Button,
    TextField,
    InputLabel,
    FormControl,
    InputAdornment,
    MenuItem,
    Grid,
    Select,
    CircularProgress,
} from '@material-ui/core';
import { sendRunnerRegister } from '../../v1apiRequest';
import { PageTitle } from '../common/PageTitle';

export const AddRunnerPage = (props) => {
    const history = useHistory();
    const { accountInfo, setAccountInfo, currentRunner, setCurrentRunner } =
        React.useContext(AccountContext);
    const [runnerInfo, setRunnerInfo] = React.useState({
        userName: '',
        gender: '',
        height: '140',
    });
    const [isUploading, setIsUploading] = React.useState(false);

    const [isFormComplete, setIsFormComplete] = React.useState(false);

    React.useEffect(() => {
        const formValues = Object.values({
            ...runnerInfo,
            showPassword: 'as',
        });
        const isAllFilled = formValues.every((f) => String(f).length > 0);

        if (isAllFilled) {
            setIsFormComplete(true);
        } else setIsFormComplete(false);
    }, [runnerInfo]);

    const handleInfoChange = (props) => (e) => {
        let _info = { ...runnerInfo };
        _info[props] = e.target.value;
        setRunnerInfo(_info);
    };

    const handleSendClick = () => {
        const sendInfo = {
            ...runnerInfo,
            ...{
                last_name: 'df',
                first_name: 'ads',
                owner: accountInfo.id,
                runner_name: runnerInfo.userName,
                calibrated_height: runnerInfo.height,
            },
        };
        console.log(sendInfo);
        setIsUploading(true);
        sendRunnerRegister(sendInfo)
            .then((res) => {
                //alert('account created');
                console.log(res.data);
                setIsUploading(false);
                localStorage.setItem('currentRunner', String(res.data.id));
                history.push('/');

                // 今度はここからトークンを取りに行く処理
            })
            .catch((error) => {
                console.log(error);
                setIsUploading(false);
            });
    };

    return (
        <div
            style={{
                textAlign: 'center',
                backgroundColor: '#e6eae6',
                height: '1000px',
            }}
        >
            <PageTitle title="ランナーを追加" />
            <div>
                <TextField
                    style={{
                        marginTop: '20px',
                        width: '70%',
                    }}
                    value={runnerInfo.userName}
                    label={
                        <span
                            style={{
                                fontSize: '0.88rem',
                            }}
                        >
                            ランナーの名前
                        </span>
                    }
                    onChange={handleInfoChange('userName')}
                    InputProps={{
                        style: {
                            paddingRight: '1px',
                            width: '60%',
                        },
                    }}
                    placeholder="（例）村井 輝"
                />

                <div style={{ marginTop: '20px' }}>
                    <Grid container style={{ width: '70%', marginLeft: '10%' }}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: '80%' }}
                                select
                                label={
                                    <span
                                        style={{
                                            fontSize: '0.88rem',
                                        }}
                                    >
                                        性別
                                    </span>
                                }
                                value={runnerInfo.gender}
                                onChange={handleInfoChange('gender')}
                            >
                                {['男性', '女性', 'その他'].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '80%' }}>
                                <InputLabel>身長（cm）</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={runnerInfo.height}
                                    label="Age"
                                    defaultValue={160}
                                    onChange={handleInfoChange('height')}
                                >
                                    {[...Array(81).keys()].map((_, idx) => {
                                        return (
                                            <MenuItem key={_} value={idx + 130}>
                                                {idx + 130}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {isFormComplete ? (
                isUploading ? (
                    <Button
                        variant="contained"
                        disabled
                        style={{ marginTop: '30px' }}
                    >
                        登録 <CircularProgress size={20} />
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '30px' }}
                        onClick={handleSendClick}
                    >
                        登録
                    </Button>
                )
            ) : (
                <Button
                    variant="contained"
                    disabled
                    style={{ marginTop: '30px' }}
                >
                    登録
                </Button>
            )}
        </div>
    );
};
