import React from 'react';
import { Button, Modal, Fade, Backdrop, Box } from '@mui/material';
import { style } from './style';

export const ConfirmModal = (props) => {
    const handleClose = () => {
        props.cancelFunc(false);
    };

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.confirmPaper}>
                        <h3 id="transition-modal-title">
                            比較対象はこちらで良いですか？
                        </h3>
                        <img
                            alt=""
                            width="90%"
                            src={`https://pidaten.s3.amazonaws.com/static/${props.video.thumbnail}`}
                        ></img>
                        <div
                            style={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}
                        >
                            memo:{props.video.memo}
                        </div>
                        <div id="transition-modal-description">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        onClick={props.cancelFunc}
                                        variant="outlined"
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        onClick={props.execFunc}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        これを比較対象にする
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
