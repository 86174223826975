import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, Fade, Backdrop } from '@material-ui/core';
const useStylesForModal = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#EEEEEE',
        border: '2px solid #0033CC',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export const ConfirmationModal = (props) => {
    const classes = useStylesForModal();

    const handleClose = () => {
        props.setIsModalOpen(false);
    };

    return (
        <div>
            <Modal
                className={classes.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <div className={classes.paper}>
                        <h3 id="transition-modal-title">
                            本当にこのビデオを削除しますか？
                        </h3>
                        <div id="transition-modal-description">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    onClick={props.cancelFunc}
                                    variant="outlined"
                                >
                                    キャンセル
                                </Button>
                                <Button
                                    onClick={props.execFunc}
                                    variant="outlined"
                                    color="primary"
                                >
                                    ビデオを削除
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
