import React from 'react';
import { AccountContext } from '../../../App';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetVideo } from '../../../hooks/useGetVideos';
import { useGetVideoByRunner } from '../../../hooks/useGetVideoByRunner';
import { AnalysisView } from './AnalysisView';
import { PageTitle } from '../../common/PageTitle';
import { SearchBar } from '../../../utils/SearchBar';
import { SearchByTag } from '../ResultPage/components/modals/SearchByTag';

export const AnalyzePage = () => {
    const { currentRunner } = React.useContext(AccountContext);
    const { videos, isVideoLoading } = useGetVideoByRunner(currentRunner.id);

    const [searchWord, setSearchWord] = React.useState('');
    const [phase, setPhase] = React.useState('middle');
    const [filteredVideos, setFilteredVideos] = React.useState([]);

    React.useEffect(() => {
        const _videos = videos.filter((v) => !v.is_guest);
        setFilteredVideos(_videos);
    }, [videos]);

    const handleChangeTag = (event) => {
        const locPhase = event.target.value;
        setPhase(event.target.value);
        handleFilter(videos, locPhase, searchWord);
    };

    function handleFilter(videos, phase, searchWord) {
        if (phase === '' || phase === 'None') {
            var result = videos.filter(function (v) {
                return !v.is_guest && v.video.memo.indexOf(searchWord) !== -1;
            });
            console.log(result);
            setFilteredVideos(result);
        } else {
            const result = videos.filter(function (v) {
                return (
                    !!v.phase &&
                    !v.is_guest &&
                    v.phase.indexOf(phase) !== -1 &&
                    v.memo.indexOf(searchWord) !== -1
                );
            });
            setFilteredVideos(result);
        }
    }

    const handleSearch = () => {
        console.log(searchWord);
        handleFilter(videos, phase, searchWord);
    };

    const handleChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    };

    let filteredScore = [];
    filteredVideos.map((v) => {
        if (!!v.score && v.score.pitch) {
            filteredScore.push(v.score);
        }
    });

    return (
        <div style={{ textAlign: 'center' }}>
            <PageTitle title={'データ'} />
            <Grid container>
                <Grid item xs={7}>
                    <SearchBar
                        label="メモでフィルタ"
                        placeholder="（例）流し"
                        onIconClick={handleSearch}
                        func={handleChangeSearchWord}
                    />
                </Grid>
                <Grid item xs={5}>
                    <SearchByTag
                        phase={phase}
                        handleChangeTag={handleChangeTag}
                    />
                </Grid>
            </Grid>
            <div
                style={{
                    textAlign: 'center',
                    padding: '5px',
                    overflowY: 'scroll',
                    height: `${window.parent.screen.height * 0.85}px`,
                }}
            >
                {isVideoLoading ? (
                    <div>
                        <div style={{ color: 'white' }}>
                            Now Score Loading
                            <CircularProgress color="success" />
                        </div>
                    </div>
                ) : (
                    <AnalysisView
                        scores={filteredScore}
                        height={currentRunner.calibrated_height}
                    />
                )}
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};
