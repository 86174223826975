import React from 'react';
import { SpeedCurve } from './SpeedCurve';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../components.css';

export const DetailedInfo = ({ video, runner, height }) => {
    const [info, setInfo] = React.useState({});
    const [isDataLoading, setIsDataLoading] = React.useState({});
    const [isFpsModalOpen, setIsFpsModalOpen] = React.useState(false);

    // 動画のIDを元にビデオを持ってくる
    React.useEffect(() => {
        setIsDataLoading(true);
        axios
            .get(`https://pidaten.s3.amazonaws.com/result/${video.file}.json`)
            .then((res) => {
                setInfo(res.data);
                console.log(res.data);
                setIsDataLoading(false);
                if (!!res.data.video_fps && res.data.video_fps < 100) {
                    setIsFpsModalOpen(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsDataLoading(false);
            });
    }, [video]);
    return (
        <div style={{ textAlign: 'center', overflowY: 'scroll' }}>
            <SpeedCurve video={video} height={height} />
            {isDataLoading ? (
                <>
                    {' '}
                    <span style={{ color: 'white' }}>データをロード中</span>
                    <CircularProgress style={{ color: '#00984f' }} />
                </>
            ) : (
                <div style={{ marginTop: '20px' }}>
                    <table id="detailTable">
                        <tbody>
                            <tr>
                                <th>ピッチ</th>
                                <td>{calibrateNum(info.pitch)}</td>
                            </tr>

                            <tr>
                                <th>左サイクル（秒）</th>
                                <td>{calibrateNum(info.anL_flighttime)}</td>
                            </tr>
                            <tr>
                                <th>右サイクル（秒）</th>
                                <td>{calibrateNum(info.anR_flighttime)}</td>
                            </tr>

                            <tr>
                                <th>左右サイクル比（左:右）</th>
                                {info.flighttime_balance === -1 ? (
                                    <td> 解析できませんでした</td>
                                ) : (
                                    <td>
                                        {parseInt(
                                            calibrateNum(
                                                info.flighttime_balance
                                            ) * 100
                                        )}
                                        % &nbsp;:&nbsp;
                                        {parseInt(
                                            (1 -
                                                calibrateNum(
                                                    info.flighttime_balance
                                                )) *
                                                100
                                        )}
                                        %
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <th>前傾角度(°)</th>
                                <td>{calibrateNum(info.inclination_raw)}</td>
                            </tr>

                            <tr>
                                <th>接地時の膝関節屈曲角(°)</th>
                                <td>{calibrateNum(info.waist_raw)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

function calibrateNum(str) {
    const num = Number(str);
    if (num === -1 || num === -1000) {
        return '解析できませんでした';
    }
    if (num < 0) {
        return Math.abs(num).toFixed(2);
    }
    if (num >= 0) {
        return Math.abs(num).toFixed(2);
    }
}
