import React from 'react';
import axios from 'axios';

import { Grid, Button, CircularProgress } from '@mui/material';

import { sortVideoByDate } from '../../videoListPage/VideoListPage';
import { ComparisonGraphs } from './ComparisonGraphs';
import {} from '../../../../v1apiRequest';
import { ConfirmModal } from './modals/ConfirmModal';
import { VideoListModal } from './modals/VideoListModal';
import { useGetVideoByRunner } from '../../../../hooks/useGetVideoByRunner';

export const CompareScreen = ({ originVideo, runner, height }) => {
    const [counterpart, setCounterpart] = React.useState({});
    const [modalShow, setModalShow] = React.useState(false);
    const [confirmModalShow, setConfirmModalShow] = React.useState(false);
    const [videoLists, setVideoLists] = React.useState([]);

    const { videos, isVideoLoading } = useGetVideoByRunner(runner.id);

    const handleClick = () => {
        setModalShow(true);
    };

    const handleSetVideo = (video) => () => {
        if (video) {
            console.log(video);
            setCounterpart(video);
            setConfirmModalShow(true);
        }
    };

    const handleDecide = () => {
        setConfirmModalShow(false);
        setModalShow(false);
    };

    const handleCancel = () => {
        setConfirmModalShow(false);
    };

    React.useEffect(() => {
        const analyzedVideos = videos.filter(
            (v) => !!v.score && !!v.score.pitch
        );
        setVideoLists(sortVideoByDate(analyzedVideos));
    }, [videos]);

    return (
        <div style={{ textAlign: 'center', overflowY: 'scroll' }}>
            <VideoListModal
                isModalOpen={modalShow}
                setIsModalOpen={setModalShow}
                videos={videoLists}
                handleSetVideo={handleSetVideo}
                currentSelected={originVideo}
            />
            <ConfirmModal
                isModalOpen={confirmModalShow}
                setIsModalOpen={setConfirmModalShow}
                video={counterpart}
                execFunc={handleDecide}
                cancelFunc={handleCancel}
            />
            {!counterpart.id ? (
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <img
                            alt=""
                            width="90%"
                            src={`https://pidaten.s3.amazonaws.com/static/${originVideo.thumbnail}`}
                        ></img>
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {isVideoLoading ? (
                                <Button
                                    size="large"
                                    variant="contained"
                                    disabled
                                >
                                    比較対象を選択
                                    <CircularProgress size={16} />
                                </Button>
                            ) : (
                                <>
                                    {!!originVideo.score &&
                                    originVideo.score.pitch ? (
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleClick}
                                        >
                                            比較対象を選択
                                        </Button>
                                    ) : (
                                        <Button
                                            size="large"
                                            variant="contained"
                                            disabled
                                            style={{
                                                backgroundColor: '#666666',
                                            }}
                                        >
                                            比較対象を選択
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <img
                                alt=""
                                width="90%"
                                src={`https://pidaten.s3.amazonaws.com/static/${originVideo.thumbnail}`}
                            ></img>
                        </Grid>
                        <Grid item xs={6}>
                            <img
                                alt=""
                                width="90%"
                                src={`https://pidaten.s3.amazonaws.com/static/${counterpart.thumbnail}`}
                            ></img>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleClick}
                                style={{
                                    margin: '4px',
                                    color: '#DDFFFF',
                                    fontWeight: 'bold',
                                }}
                            >
                                比較対象を変更する
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            {counterpart.id ? (
                <ComparisonGraphs
                    beforeInfo={originVideo.score}
                    height={height}
                    counterVideo={counterpart}
                    currentVideo={originVideo}
                />
            ) : (
                <></>
            )}
        </div>
    );
};
