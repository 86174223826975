import React from 'react';

export const PageTitle = (props) => {
    return (
        <div
            style={{
                backgroundColor: '#000066',
                fontSize: '9pt',
                fontWeight: 'bold',
                padding: '5px',
                textAlign: 'center',
                color: 'white',
            }}
        >
            {props.title}
        </div>
    );
};
