import React from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const RadioChoices = ({
    optionList,
    handleTagChange,
    formLabel,
    tag,
}) => {
    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
                <span style={{ color: '#e6eae6', fontWeight: 'bold' }}>
                    {formLabel}
                </span>
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleTagChange(tag)}
            >
                {optionList.map((option) => {
                    return (
                        <FormControlLabel
                            value={option.value}
                            label={option.label}
                            control={
                                <Radio
                                    sx={{
                                        color: '#bbe2f1',
                                        padding: '5px',
                                        fontSize: '1.2rem',
                                    }}
                                />
                            }
                            style={{ color: '#bbe2f1', fontSize: '12pt' }}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};
