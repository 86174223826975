import React from 'react';
import axios from 'axios';
import { Paper } from '@mui/material';

import { SpeedCurve } from './SpeedCurve';

import '../components.css';

export const StartInfo = ({ info, videoInfo, height }) => {
    return (
        <div style={{ textAlign: 'center', overflowY: 'scroll' }}>
            <div style={{ fontSize: '9pt', color: '#f5f5f5' }}>
                <Paper
                    variant="outlined"
                    style={{
                        backgroundColor: '#001e43',
                    }}
                >
                    <video
                        style={{ margin: '5px' }}
                        id="videoBasic"
                        src={`https://pidaten.s3.amazonaws.com/static/${videoInfo.file}`}
                        controls
                        muted
                        loop
                        poster={`https://pidaten.s3.amazonaws.com/static/${videoInfo.thumbnail}`}
                    ></video>
                </Paper>
            </div>
            <SpeedCurve video={videoInfo} height={height} showDetail={true} />
        </div>
    );
};
