import React from 'react';
import axios from 'axios';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import { StartDetailExplanation } from './StartDetailExplanation';
import CircularProgress from '@mui/material/CircularProgress';

import '../components.css';

export const DoubleSpeedCurve = ({ origin, counter, height, showDetail }) => {
    const [originData, setOriginData] = React.useState([]);
    const [counterData, setCounterData] = React.useState([]);

    React.useEffect(() => {
        axios
            .get(
                `https://pidaten.s3.amazonaws.com/result_csv_detail/${origin.file}.csv`
            )
            .then((res) => {
                console.log('get origin');
                const csv = csv_To_Array(res.data);
                setOriginData(crtVelData(csv, height));
                axios
                    .get(
                        `https://pidaten.s3.amazonaws.com/result_csv_detail/${counter.file}.csv`
                    )
                    .then((res) => {
                        console.log('got counter');

                        const csv = csv_To_Array(res.data);
                        setCounterData(crtVelData(csv, height));
                    });
            });
    }, [height]);
    let domain = [];
    let concatData = [];
    if (originData.length > 0 && counterData.length > 0) {
        const originDom = crt_domain(originData);
        const counterDom = crt_domain(counterData);
        console.log(originDom, counterDom);
        domain = mergeDom(originDom, counterDom);
        concatData = mergeData(originData, counterData);
    }

    console.log(domain);
    console.log(originData);
    console.log(counterData);
    console.log(concatData);

    if (
        originData.length > 0 &&
        originData[1].meter !== 'NaN' &&
        counterData.length > 0 &&
        counterData[1].meter !== 'NaN' &&
        concatData.length > 0 &&
        origin.phase === 'start' &&
        counter.phase === 'start'
    ) {
        return (
            <>
                <div style={{ color: '#fff', padding: '5px' }}>
                    速度曲線（m/s）
                </div>
                <LineChart
                    className="rechars-surface"
                    data={concatData}
                    width={window.parent.screen.width * 0.85}
                    height={window.parent.screen.width * 0.5}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="meter"
                        //allowDuplicatedCategory={false}
                        tick={{ fontSize: 8 }}
                        unit="m"
                    />
                    <YAxis
                        dataKey="left"
                        domain={domain}
                        tick={{ fontSize: 8, fontWeight: 'bold' }}
                        width={20}
                    />
                    <Tooltip
                        labelStyle={{ color: 'green' }}
                        labelFormatter={function (label) {
                            return `${label}m from start`;
                        }}
                    />
                    <Legend verticalAlign="top" height={26} />

                    <Line
                        dot={false}
                        dataKey="left"
                        stroke="#66cdaa"
                        strokeWidth={2}
                    />
                    <Line
                        dot={false}
                        dataKey="right"
                        stroke="#ffa500"
                        strokeWidth={2}
                    />
                </LineChart>

                {/* {showDetail ? (
                    <StartDetailExplanation data={data} max={max} />
                ) : null} */}
            </>
        );
    }
    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ color: '#f5f5f5', fontSize: '12pt' }}>
                速度曲線を描画中
            </div>

            <CircularProgress color="success" />
        </div>
    );
};

function csv_To_Array(str, delimiter = ',') {
    const header_cols = str.slice(0, str.indexOf('\n')).split(delimiter);
    const row_data = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = row_data.map(function (row) {
        const values = row.split(delimiter);
        const el = header_cols.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    // return the array
    return arr;
}

function crtVelData(data, height) {
    let retList = [];
    //console.log(data);
    for (var row of data) {
        if (!row.velocity) {
            continue;
        }
        retList.push({
            velocity: Number((row.velocity * height).toFixed(3)),
            velocity_raw: Number((row.velocity_raw * height).toFixed(3)),
            frame_id: Number(row.frame_id / 120).toFixed(3),
            meter: Number(row.gp_x_m * height).toFixed(1),
        });
    }

    // meterが入っていた場合はmeterでソート
    if (data[0].meter !== 'NaN') {
        retList = sortVideoByMeter(retList);
        return retList;
    }

    return retList;
}

function sortVideoByMeter(videoList) {
    return videoList.sort(function (a, b) {
        if (Number(a.meter) < Number(b.meter)) return -1;
        if (Number(a.meter) > Number(b.meter)) return 1;
        return 0;
    });
}

function crt_domain(data) {
    if (data.length < 1) {
        return;
    }
    let max = 0;
    let min = 2000;
    const ary = data.map((row) => {
        if (row.velocity > max) {
            max = row.velocity;
        }
        if (row.velocity < min) {
            min = row.velocity;
        }
        return row.velocity;
    });

    return [Number((min * 0.6).toFixed(1)), Number((max * 1.05).toFixed(1))];
}

function mergeDom(data1, data2) {
    const min = Math.min(data1[0], data2[0], 0);
    const max = Math.max(data1[1], data2[1], 0);
    return [min, max];
}

function mergeData(origin, counter) {
    //mを揃えるための関数。入力のorigin, counterはメートルで小さい順にソートされている前提
    let concatData = {};
    const [smallerKey, largerKey] =
        Number(origin[0].meter) <= Number(counter[0].meter)
            ? ['left', 'right']
            : ['right', 'left'];
    const [smallerData, largerData] =
        Number(origin[0].meter) <= Number(counter[0].meter)
            ? [origin, counter]
            : [counter, origin];

    for (let row of smallerData) {
        const dist = row.meter;
        if (!concatData[dist]) {
            // なかった場合
            concatData[dist] = {
                [smallerKey]: row.velocity,
                meter: row.meter,
            };
        }
        continue;
    }

    for (let row of largerData) {
        const dist = row.meter;
        if (!concatData[dist]) {
            // なかった場合
            concatData[dist] = {
                [largerKey]: row.velocity,
                meter: row.meter,
            };
            continue;
        }
        // あった場合
        concatData[dist] = { ...concatData[dist], [largerKey]: row.velocity };
    }

    // 続いてconcatDataをリストに直す
    let retList = [];
    for (const keyName of Object.keys(concatData)) {
        retList.push(concatData[keyName]);
    }
    console.log(retList);

    return retList;
}
