import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const scrollToCheckbox = (videoSrc) => {
    const targetElement = document.getElementById('checkboxes');

    // 画面上部から要素までの距離
    const rectTop = targetElement.getBoundingClientRect().top;
    // 現在のスクロール距離
    //const offsetTop = window.pageYOffset;
    // スクロール位置に持たせるバッファ
    const buffer = 50;
    const top = !!videoSrc ? rectTop - buffer - 50 : rectTop - buffer;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const goToTags = () => {
    const targetElement = document.getElementById('tags');
    const rectTop = targetElement.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    const buffer = 100;
    const top = rectTop + offsetTop - buffer;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const goToSendButton = () => {
    const targetElement = document.getElementById('sendButton');
    const rectTop = targetElement.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    const buffer = 100;
    const top = rectTop + offsetTop - buffer;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};
