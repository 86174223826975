import React from 'react';

export const AnalysisStatusLabel = (props) => {
    const status = props.status;

    if (!status) {
        return (
            <span
                style={{
                    fontWeight: 'bold',
                    fontSize: '8pt',
                    borderRadius: '10px',
                    backgroundColor: '#c0c0c0',
                    padding: '1px',
                }}
            >
                &nbsp;解析待ち&nbsp;
            </span>
        );
    }

    if (status === 'value_error') {
        return (
            <span
                style={{
                    fontWeight: 'bold',
                    backgroundColor: '#33CC33',
                    color: 'white',
                    fontSize: '8pt',
                    borderRadius: '10px',
                    padding: '1px',
                }}
            >
                &nbsp;解析完了&nbsp;
            </span>
        );
    }

    if (status === 'done') {
        return (
            <span
                style={{
                    fontWeight: 'bold',
                    backgroundColor: '#33CC33',
                    color: 'white',
                    fontSize: '8pt',
                    borderRadius: '10px',
                    padding: '1px',
                }}
            >
                &nbsp;解析完了&nbsp;
            </span>
        );
    }

    if (status === 'no_json_error') {
        return (
            <span
                style={{
                    fontWeight: 'bold',
                    backgroundColor: '#ea553a',
                    color: 'white',
                    fontSize: '8pt',
                    borderRadius: '10px',
                    padding: '1px',
                }}
            >
                &nbsp;解析失敗&nbsp;
            </span>
        );
    }
    return null;
};
