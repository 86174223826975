// トップスピードに対して100mのタイムを返す
export function estimateTime(meanSpeed) {
    let estTime = 0;
    if (meanSpeed >= 10.0) {
        // (10.0, 11"20)(11.65, 10"00)を通るように計算
        estTime = -0.7273 * meanSpeed + 18.473;
    } else if (meanSpeed >= 8.7) {
        // (10.0, 11"20)(8.7, 12"79)を通るように計算
        estTime = -1.223 * meanSpeed + 23.43;
    } else {
        estTime = 29.353 - 1.9026 * meanSpeed;
    }

    const byo = parseInt(estTime);
    console.log(estTime);

    let syosu = parseInt((estTime - byo) * 100);
    if (syosu < 10) {
        syosu = String('0') + String(syosu);
    }
    return String(byo) + '秒' + String(syosu);
}
