import React from 'react';
import { getVideoByRunner } from '../v1apiRequest';
import CircularProgress from '@mui/material/CircularProgress';

export const useGetVideoByRunner = (runnerId) => {
    const [videos, setVideos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!runnerId) {
            return;
        }
        setLoading(true);
        getVideoByRunner(runnerId)
            .then((res) => {
                setVideos(res.data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                console.log(false);
            });
    }, [runnerId]);

    const loadingView = loading ? (
        <div style={{ color: 'white' }}>
            Now Loading
            <CircularProgress color="success" />
        </div>
    ) : null;

    return {
        videos: videos,
        isVideoLoading: loading,
        videoLoadingView: loadingView,
    };
};
