import React from 'react';

import {
    Paper,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from '@material-ui/core';

export const HeightSelecter = (props) => {
    return (
        <FormControl
            style={{
                width: '60%',
            }}
        >
            <InputLabel style={{ color: 'white' }}>身長（cm）</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.height}
                label="Age"
                defaultValue={props.height}
                onChange={props.handleHeightChange}
                style={{ color: 'white' }}
                inputProps={{ color: 'red' }}
            >
                {[...Array(81).keys()].map((_, idx) => {
                    return (
                        <MenuItem key={_} value={idx + 130}>
                            {idx + 130}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
