import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
    IconButton,
    Typography,
    InputAdornment,
    TextField,
} from '@material-ui/core';

export const SearchBar = ({
    label,
    func,
    onIconClick,
    length,
    placeholder,
}) => {
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onIconClick();
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <TextField
                id="search"
                variant="outlined"
                onChange={func}
                onKeyPress={_handleKeyDown}
                //className={ classes.root }
                label={
                    <span
                        style={{
                            fontSize: '0.68rem',
                            color: '#99CCFF',
                        }}
                    >
                        {label}
                    </span>
                }
                margin="dense"
                placeholder={placeholder}
                style={{
                    width: length,
                    marginTop: '10px',
                    paddingRight: '1px',
                    marginLeft: '10px',
                }}
                InputProps={{
                    style: {
                        backgroundColor: '#202020',
                        paddingRight: '1px',
                        color: 'white',
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <SearchIcon
                                    onClick={onIconClick}
                                    color="primary"
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};
