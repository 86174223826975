import React from 'react';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { patchVideo } from '../../../../v1apiRequest';

export const VideoEdit = (props) => {
    const [info, setInfo] = React.useState(props.video);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isButtonActive, setIsButtonActive] = React.useState(false);

    const handleFormChange = (keyName) => (e) => {
        setInfo({ ...info, [keyName]: e.target.value });
    };

    React.useEffect(() => {
        if (info.memo === props.video.memo) {
            setIsButtonActive(false);
            return;
        }
        setIsButtonActive(true);
        return;
    }, [info]);

    const handlePatchVideo = () => {
        setIsUploading(true);
        patchVideo(info.id, info)
            .then((res) => {
                alert('変更完了！');
                console.log(res.data);
                setIsUploading(false);
                setIsButtonActive(false);
            })
            .catch((e) => {
                console.log(e);
                setIsUploading(false);
            });
    };

    return (
        <>
            <div>
                <TextField
                    variant="outlined"
                    label={
                        <span
                            style={{
                                fontSize: '12pt',
                                color: '#99CCFF',
                            }}
                        >
                            {'動画のメモを編集'}
                        </span>
                    }
                    style={{
                        width: '100%',
                        marginTop: '10px',
                    }}
                    InputProps={{
                        style: {
                            backgroundColor: '#202020',
                            paddingRight: '1px',
                            color: 'white',
                        },
                    }}
                    multiline
                    placeholder="（例）出力60%, ピッチ意識, など"
                    value={info.memo}
                    onChange={handleFormChange('memo')}
                />
            </div>

            <div>
                {isUploading ? (
                    <div style={{ marginTop: '20px', color: 'white' }}>
                        変更を保存中 <CircularProgress size={20} />
                    </div>
                ) : isButtonActive ? (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#1e90ff',
                            fontWeight: 'bold',
                            color: '#EEEEEE',
                            marginTop: '20px',
                        }}
                        onClick={handlePatchVideo}
                    >
                        変更保存
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#aaaaaa',
                            fontWeight: 'bold',
                            color: '#EEEEEE',
                            marginTop: '20px',
                        }}
                        disabled
                    >
                        変更保存
                    </Button>
                )}
            </div>
        </>
    );
};
