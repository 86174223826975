import React from 'react';

export const Announce = () => {
    const a = 'a';
    return (
        <>
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    border: '2px solid #cccccc',
                    margin: '10px',
                    color: '#cccccc',
                }}
            >
                - 運営からのお知らせ -
                <div
                    style={{
                        textAlign: 'left',
                        padding: '6px',
                    }}
                >
                    この度はご利用ありがとうございます。ご利用上お困りの際は
                    <span>
                        <a
                            href="https://lin.ee/dZkOjsz"
                            target="_blank"
                            style={{ color: 'lightblue' }}
                        >
                            こちらのリンク
                        </a>
                        からお問い合わせをお願いできますと幸いです。
                    </span>
                </div>
            </div>
        </>
    );
};
