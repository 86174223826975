import React from 'react';

import '../components.css';

export const StartDetailExplanation = ({ data, max }) => {
    console.log(data);
    const result = calAcceleration(data);
    const accList = renderAcceleration(result);
    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <table id="detailTable">
                    <tbody>
                        <tr>
                            <th>区間内最高速度（m/s）</th>
                            <td>{calibrateNum(max)}&nbsp;</td>
                        </tr>
                        {accList.map((row) => (
                            <>
                                {/* <tr>
                                    <th>
                                        {parseInt(row.meter)}mまでの秒数
                                        <br />
                                        （動き出しから）
                                    </th>
                                    <td>{calibrateNum(row.sec)}&nbsp;</td>
                                </tr> */}
                                <tr>
                                    <th>
                                        {parseInt(row.meter)}mでの速度（m/s）
                                    </th>
                                    <td>{calibrateNum(row.velocity)}&nbsp;</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

function renderAcceleration(list) {
    let resList = [];
    for (let i = 0; i < list.length; i++) {
        if (i < 1) {
            continue;
        } else {
            const deltaSec = list[i].frame_id - list[i - 1].frame_id;
            const acc =
                (list[i].velocity - list[i - 1].velocity) / (deltaSec + 0.0001);
            resList.push({
                meter: list[i].meter,
                sec: list[i].frame_id - list[0].frame_id,
                velocity: list[i].velocity,
            });
        }
    }
    console.log(resList);
    return resList;
}

function calAcceleration(data) {
    // 5mごとの区間情報
    let result = [];
    for (let i = 0; i < data.length; i++) {
        if (!data[i].meter) {
            return;
        }
        if (i === 0) {
            result.push(data[i]);
            continue;
        }

        if (
            i > 2 &&
            Number(data[i].meter) >= 5.0 &&
            Number(data[i - 1].meter) < 5
        ) {
            result.push(data[i]);
            continue;
        }

        if (
            i > 2 &&
            Number(data[i].meter) >= 10.0 &&
            Number(data[i - 1].meter) < 10
        ) {
            result.push(data[i]);
            continue;
        }
    }
    console.log(result);
    return result;
}

function calibrateNum(str) {
    const num = Number(str);
    if (num === -1 || num === -1000) {
        return '解析できませんでした';
    }
    if (num < 0) {
        return Math.abs(num).toFixed(2);
    }
    if (num >= 0) {
        return Math.abs(num).toFixed(2);
    }
}
