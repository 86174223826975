import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

export const TabInfo = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const labels = props.labels;
    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="fullWidth"
            >
                {props.labels.map((label) => (
                    <Tab
                        label={label}
                        key={label}
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                    ></Tab>
                ))}{' '}
                {/* さっきの */}
            </Tabs>
            {props.children.map((child, index) => (
                <TabPanel
                    value={value}
                    index={index}
                    key={index}
                    bgColor={props.bgColor}
                >
                    {child}
                </TabPanel>
            ))}
        </div>
    );
};

const TabPanel = (props) => {
    const { children, value, index, bgColor = '#333333', ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    p={3}
                    style={{ backgroundColor: bgColor, padding: '12px' }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
};
