import React from 'react';
import axios from 'axios';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import { StartDetailExplanation } from './StartDetailExplanation';

import '../components.css';

export const SpeedCurve = ({ video, height, showDetail }) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        axios
            .get(
                `https://pidaten.s3.amazonaws.com/result_csv_detail/${video.file}.csv`
            )
            .then((res) => {
                const csv = csv_To_Array(res.data);
                //console.log(csv);
                setData(crtVelData(csv, height));
            });
    }, [height]);
    let domain = [];
    let max = 0;
    if (data.length > 0) {
        const { calDomain, calMax } = crt_domain(data);
        domain = calDomain;
        max = calMax;
    }

    if (data.length > 0 && data[1].meter !== 'NaN') {
        return (
            <>
                <div style={{ color: '#fff', padding: '5px' }}>
                    速度曲線（m/s）
                </div>
                <LineChart
                    className="rechars-surface"
                    data={data}
                    width={window.parent.screen.width * 0.85}
                    height={window.parent.screen.width * 0.5}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="meter"
                        //allowDuplicatedCategory={false}
                        tick={{ fontSize: 8 }}
                        unit="m"
                    />
                    <YAxis
                        dataKey="velocity"
                        domain={domain}
                        tick={{ fontSize: 8, fontWeight: 'bold' }}
                        width={20}
                    />
                    <Tooltip
                        labelStyle={{ color: 'green' }}
                        labelFormatter={function (label) {
                            return `${label}m from start`;
                        }}
                    />
                    <Legend verticalAlign="top" height={26} />

                    <Line
                        dot={false}
                        dataKey="velocity"
                        stroke="#66cdaa"
                        strokeWidth={2}
                    />
                </LineChart>

                {showDetail ? (
                    <StartDetailExplanation data={data} max={max} />
                ) : null}
            </>
        );
    }

    return (
        <>
            {data.length > 0 ? (
                <>
                    <div style={{ color: '#fff', padding: '5px' }}>
                        速度曲線（m/s）
                    </div>
                    <LineChart
                        className="rechars-surface"
                        data={data}
                        width={window.parent.screen.width * 0.85}
                        height={window.parent.screen.width * 0.5}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="frame_id"
                            //allowDuplicatedCategory={false}
                            tick={{ fontSize: 8 }}
                            unit="s"
                        />
                        <YAxis
                            dataKey="velocity"
                            domain={domain}
                            tick={{ fontSize: 8, fontWeight: 'bold' }}
                            width={20}
                        />
                        <Tooltip
                            labelStyle={{ color: 'green' }}
                            labelFormatter={function (label) {
                                return `${label}s from start`;
                            }}
                        />
                        <Legend verticalAlign="top" height={26} />

                        <Line
                            dot={false}
                            dataKey="velocity"
                            stroke="#66cdaa"
                            strokeWidth={2}
                        />
                    </LineChart>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

function csv_To_Array(str, delimiter = ',') {
    const header_cols = str.slice(0, str.indexOf('\n')).split(delimiter);
    const row_data = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = row_data.map(function (row) {
        const values = row.split(delimiter);
        const el = header_cols.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    // return the array
    return arr;
}

function crtVelData(data, height) {
    let retList = [];
    console.log(data);
    for (var row of data) {
        if (!row.velocity || row.velocity === '') {
            continue;
        }
        retList.push({
            velocity: Number((row.velocity * height).toFixed(3)),
            velocity_raw: Number((row.velocity_raw * height).toFixed(3)),
            frame_id: Number(row.frame_id / Number(row.fps)).toFixed(3),
            meter: Number(row.gp_x_m * height).toFixed(2),
        });
    }

    console.log(retList);

    // meterが入っていた場合はmeterでソート
    if (data[0].meter !== 'NaN') {
        retList = sortVideoByMeter(retList);
        return retList;
    }

    return retList;
}

function sortVideoByMeter(videoList) {
    return videoList.sort(function (a, b) {
        if (Number(a.meter) < Number(b.meter)) return -1;
        if (Number(a.meter) > Number(b.meter)) return 1;
        return 0;
    });
}

function crt_domain(data) {
    if (data.length < 1) {
        return;
    }
    let max = 0;
    let min = 2000;
    const ary = data.map((row) => {
        if (row.velocity > max) {
            max = row.velocity;
        }
        if (row.velocity < min) {
            min = row.velocity;
        }
        return row.velocity;
    });

    console.log(max);
    return {
        calDomain: [
            Number((min * 0.6).toFixed(1)),
            Number((max * 1.05).toFixed(1)),
        ],
        calMax: max,
    };
}
