import React from 'react';
import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteVideoById } from '../../../../v1apiRequest';
import { AnalysisStatusLabel } from './AnalysisStatusLabel';
import { ConfirmationModal } from './ConfirmationModal';
import { formatDate } from '../VideoListPage';
import { TagViewer } from './TagViewer';
import { LowFPSAlert } from './LowFPSAlert';
export const SingleVideoCard = (props) => {
    // props.の中には上から降りてきてる引数が入ってる
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClickTrashBox = () => {
        setIsModalOpen(true);
    };
    const handleVideoDelete = (vId) => () => {
        deleteVideoById(vId)
            .then((res) => {
                console.log(res);
                window.location.reload(); //FIXME: ここはAPIをもう一回叩くくらいで良いかも
            })
            .catch((e) => console.log(e));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [hiduke, jikoku] = formatDate(props.video.uploaded_date);
    const analysisStatus = props.video.analysis_status;

    return (
        <div id="videoCard">
            <ConfirmationModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                execFunc={handleVideoDelete(props.video.id)}
                cancelFunc={handleCancel}
            />{' '}
            <Grid container>
                <Grid item xs={4}>
                    <div>
                        <img
                            id="blurred"
                            alt=""
                            onClick={props.handleClick(props.video)}
                            width="80%"
                            src={`https://pidaten.s3.amazonaws.com/static/${props.video.thumbnail}`}
                        ></img>
                    </div>
                </Grid>

                <Grid item xs={5}>
                    <div style={{ textAlign: 'left' }}>
                        <div
                            style={{
                                fontSize: '10pt',
                                display: 'flex',
                                paddingLeft: '10px',
                            }}
                        >
                            <div
                                onClick={props.handleClick(props.video.id)}
                                id="white-char"
                                style={{ fontSize: '8pt' }}
                            >
                                {hiduke}
                                &nbsp;
                                {jikoku}
                                <br style={{ marginTop: '8px' }} />
                                {convertMemo(props.video.memo)}
                                <div
                                    style={{
                                        marginTop: '5px',
                                        display: 'flex',
                                    }}
                                >
                                    <TagViewer tag={props.video.phase} />
                                    <TagViewer
                                        tag={props.video.effort}
                                        marginLeft={'3px'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ marginLeft: 'px' }}>
                        {/* マニュアル制御の時はこっち */}

                        <AnalysisStatusLabel status={analysisStatus} />

                        {!analysisStatus ? (
                            <WaitTime
                                estTime={props.video.estimated_complete_date}
                            />
                        ) : null}

                        <LowFPSAlert fps={120} />
                        {props.video.is_guest ? (
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: '9pt',
                                    padding: '1px',
                                    borderRadius: '7px',
                                }}
                            >
                                ゲスト
                            </div>
                        ) : null}
                        <div>
                            {props.hideDeleteButton ? null : (
                                <span>
                                    <DeleteIcon
                                        fontSize="small"
                                        onClick={handleClickTrashBox}
                                        style={{ color: '#EEEEEE' }}
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

function convertMemo(str) {
    if (str.length < 10) {
        return str;
    }
    if (str === 'None') {
        return '';
    }
    return str.slice(0, 18) + '...';
}

const WaitTime = ({ estTime }) => {
    // estTimeと現在時刻との差を計算する
    let nowDate = new Date();
    let estDate = new Date(estTime);
    var diffMin = (estDate.getTime() - nowDate.getTime()) / 1000 / 60;
    const waitMin = diffMin > 0 ? parseInt(diffMin) + 1 : 1;
    return (
        <div style={{ color: 'white', fontSize: '10pt', fontWeight: 'bold' }}>
            {waitMin}&nbsp;
            <span style={{ color: 'white', fontSize: '8pt' }}>分待ち</span>
        </div>
    );
};
