export const style = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '94%',
        backgroundColor: 'rgba(0,25,67,0.87)',
        border: '2px solid #444',
        boxShadow: 24,
        padding: '12px',
        paddingTop: '6px',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.6}px`,
    },
    confirmPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        backgroundColor: '#c0c0c0',
        border: '2px solid #444',
        boxShadow: 24,
        padding: '12px',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.6}px`,
    },

    AnalysisErrorPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        backgroundColor: '#f8f8ff',
        border: '2px solid #444',
        boxShadow: 24,
        padding: '12px',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.5}px`,
    },

    fpsAlert: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        backgroundColor: '#e6eae6',
        border: '2px solid #ffdc00',
        boxShadow: 24,
        padding: '12px',
        textAlign: 'center',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.3}px`,
    },
};
