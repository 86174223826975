import axios from 'axios';

export const SERVER_HOST = 'https://100.26.159.14:5000';
export const LOCAL_HOST = 'http://127.0.0.1:8000/api/v1';
export const TEST_SERVER_HOST = 'https://3.236.168.212:8000/api/v1';
const awsURL = 'https://d1z7ngaqpzcnk0.cloudfront.net'; // 本番用
const pidatenAws = 'https://d370lob9fj8mk0.cloudfront.net/api/v1'; // テスト用
const awsTestBack = 'https://dqh5k6grrmywd.cloudfront.net/api/v1';
const pidatenMonitor = 'https://d2kmkq5apj1fex.cloudfront.net/api/v1';

export const HOST = pidatenMonitor;

//export const SERVER_HOST = "https://alpha-order.herokuapp.com/api/v1"

export const getInfo = (videoPath) =>
    axios.get(`${HOST}/load_json/${videoPath}`);

export const sendLoginInfo = (params) =>
    axios.post(`${HOST}/login/`, {
        email: params.email,
        password: params.password,
    });

export const sendRegisterInfo = (params) =>
    axios.post(`${HOST}/register/`, params);

export const postVideo = (params) =>
    axios.post(`${HOST}/post-videos/`, params, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: setTokenToHeader(),
        },
    });

export const getVideo = (userId) =>
    axios.get(`${HOST}/videos/${userId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getVideoByRunner = (runnerId) =>
    axios.get(`${HOST}/videos-by-runner/${runnerId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getAllVideo = () =>
    axios.get(`${HOST}/all-videos/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getVideoById = (videoId) =>
    axios.get(`${HOST}/video-info/${videoId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getScoreByUserId = (userId) =>
    axios.get(`${HOST}/score-list/${userId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

// export const getScoresByRunner = (runnerId) =>
// axios.get(`${HOST}/score-runner/${runnerId}`, {
//     headers: {
//         Authorization: setTokenToHeader(),
//     },
// });

export const deleteVideoById = (videoId) =>
    axios.delete(`${HOST}/video-info/${videoId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getAccountInfo = () =>
    axios.get(`${HOST}/myinfo/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const putAccountInfo = (params) =>
    axios.put(`${HOST}/myinfo/`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

export const putRunnerInfo = (params) =>
    axios.put(`${HOST}/runner-info/`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

export const calibRunnerHeight = (params) =>
    axios.put(`${HOST}/calib-runner/`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

export const postManualScore = (params) =>
    axios.post(`${HOST}/score-manual/`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

export const patchVideo = (vId, params) =>
    axios.patch(`${HOST}/video-info/${vId}`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

export const sendRunnerRegister = (params) =>
    axios.post(`${HOST}/register-runner/`, params, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });

function setTokenToHeader() {
    const authToken = localStorage.getItem('access');
    if (authToken !== 'undefined') {
        return 'JWT ' + authToken;
    }
}

//テスト用でしか使わない
export function postScore(userId) {
    const scoreJson = {
        speed: 1.0,
        stride: 2.1,
        pitch: 4.4,
        inclination: 3,
        chin: 1.0,
        waist: 2.1,
        knee_height: 4.4,
        recovery: 3,
        user_id: userId,
        video_id: userId,
    };
    axios.post(`${HOST}/score/`, scoreJson, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: setTokenToHeader(),
        },
    });
}
