import React from 'react';
import { Button, Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const LowFPSAlert = ({ fps }) => {
    if (fps < 100) {
        return <ErrorOutlineIcon size="small" style={{ color: '#f39800' }} />;
    }
    return null;
};
