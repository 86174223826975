import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Paper } from '@material-ui/core';
import { AccountContext } from '../../../App';

import { TabInfo } from './TabInfo';
import { AthleteInfo } from './components/AthleteInfo';
import { getVideoById } from '../../../v1apiRequest';
import { DetailedInfo } from './components/DetailedInfo';
import { CompareScreen } from './components/CompareScreen';
import { StartInfo } from './components/StartInfo';
import { VideoEdit } from './components/VideoEdit';
import { LowFPSModal } from './components/modals/LowFpsModal';

export const ResultPage = (props) => {
    const { video_id } = useParams();
    const [videoFromApi, setVideoFromApi] = React.useState({});
    const { currentRunner } = React.useContext(AccountContext);
    const [isVideoLoading, setIsVideoLoading] = React.useState(false);
    const [height, setHeight] = React.useState(160); // guestの身長入れる用
    const [isFpsModalOpen, setIsFpsModalOpen] = React.useState(false);

    console.log(currentRunner);

    // 動画のIDを元にビデオを持ってくる
    React.useEffect(() => {
        setIsVideoLoading(true);
        getVideoById(video_id)
            .then((res) => {
                console.log(res.data);
                setVideoFromApi(res.data[0]);
                // 続いてそのビデオの解析結果を探しに行く

                setIsVideoLoading(false);
            })
            .catch((e) => {
                console.log('ビデオが見つかりません');
            });
    }, []);

    React.useEffect(() => {
        setHeight(currentRunner.height);
    }, [currentRunner]);

    const heightLocal = !!videoFromApi.is_guest
        ? height
        : currentRunner.calibrated_height;

    if (videoFromApi.phase === 'start') {
        const tabLabels = ['速度情報', '比較', 'メモ'];

        return (
            <div className="App">
                <LowFPSModal
                    isModalOpen={isFpsModalOpen}
                    closeFunc={() => setIsFpsModalOpen(false)}
                />
                <div
                    style={{
                        marginTop: '1px',
                        width: '98%',
                        marginLeft: '1.0%',
                    }}
                >
                    <TabInfo labels={tabLabels}>
                        {!!videoFromApi.score && !!videoFromApi.score.speed ? (
                            <StartInfo
                                videoInfo={videoFromApi}
                                account={currentRunner}
                                height={heightLocal}
                            />
                        ) : (
                            <div style={{ fontSize: '9pt', color: '#f5f5f5' }}>
                                <Paper
                                    variant="outlined"
                                    style={{
                                        backgroundColor: '#001e43',
                                    }}
                                >
                                    <video
                                        style={{ margin: '5px' }}
                                        id="videoBasic"
                                        src={`https://pidaten.s3.amazonaws.com/static/${videoFromApi.file}`}
                                        controls
                                        muted
                                        loop
                                        poster={`https://pidaten.s3.amazonaws.com/static/${videoFromApi.thumbnail}`}
                                    ></video>
                                </Paper>
                                解析結果が出るまでもうしばらくお待ちください
                            </div>
                        )}

                        <CompareScreen
                            originVideo={videoFromApi}
                            runner={currentRunner}
                            height={heightLocal}
                        />
                        <VideoEdit video={videoFromApi} />
                    </TabInfo>
                </div>
            </div>
        );
    }

    // こっちは加速走と中間疾走

    const tabLabels = ['基本情報', '詳細情報', '比較', 'メモ'];

    return (
        <div className="App">
            <LowFPSModal isModalOpen={isFpsModalOpen} />

            <div
                style={{
                    marginTop: '1px',
                    width: '98%',
                    marginLeft: '1.0%',
                }}
            >
                <TabInfo labels={tabLabels}>
                    {!!videoFromApi.score && videoFromApi.score.speed ? (
                        <AthleteInfo
                            isLoading={isVideoLoading}
                            height={heightLocal}
                            videoInfo={videoFromApi}
                            runner={currentRunner}
                            setHeight={setHeight}
                        />
                    ) : (
                        <div style={{ fontSize: '9pt', color: '#f5f5f5' }}>
                            <Paper
                                variant="outlined"
                                style={{
                                    backgroundColor: '#001e43',
                                }}
                            >
                                <video
                                    style={{ margin: '5px' }}
                                    id="videoBasic"
                                    src={`https://pidaten.s3.amazonaws.com/static/${videoFromApi.file}`}
                                    controls
                                    muted
                                    loop
                                    poster={`https://pidaten.s3.amazonaws.com/static/${videoFromApi.thumbnail}`}
                                ></video>
                            </Paper>
                            解析結果が出るまでもうしばらくお待ちください
                        </div>
                    )}

                    <DetailedInfo
                        video={videoFromApi}
                        runner={currentRunner}
                        height={heightLocal}
                    />
                    <CompareScreen
                        originVideo={videoFromApi}
                        runner={currentRunner}
                        height={heightLocal}
                    />
                    <VideoEdit video={videoFromApi} />
                </TabInfo>
            </div>
        </div>
    );
};
