import React from 'react';

import '../components.css';
import {
    Paper,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { estimateTime } from '../../../../func/estimate_100time';
import { AnalysisErrorModal } from './modals/AnalysisErrorModal';

import { PidatenScoreView } from '../../../common/PidatenScoreView';

export const AthleteInfo = (props) => {
    const [isModalShown, setIsModalShown] = React.useState(false);

    const handleModalOpen = () => {
        setIsModalShown(true);
    };

    const handleHeightChange = (e) => {
        props.setHeight(e.target.value);
    };

    const height = !!props.videoInfo.is_guest
        ? props.height
        : props.runner.calibrated_height;

    return (
        <>
            {!!props.videoInfo.is_guest ? (
                <div style={{ marginBottom: '16px', marginLeft: '40%' }}>
                    <FormControl
                        style={{
                            width: '60%',
                        }}
                    >
                        <InputLabel style={{ color: 'white' }}>
                            身長（cm）
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.height}
                            label="Age"
                            defaultValue={props.runner.height}
                            onChange={handleHeightChange}
                            style={{ color: 'white' }}
                            inputProps={{ color: 'red' }}
                        >
                            {[...Array(81).keys()].map((_, idx) => {
                                return (
                                    <MenuItem key={_} value={idx + 130}>
                                        {idx + 130}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            ) : (
                <></>
            )}
            {props.isVideoShown !== 'hidden' ? (
                props.isLoading ? (
                    <div style={{ color: 'white' }}>
                        Now Loading
                        <CircularProgress color="success" />
                    </div>
                ) : props.videoInfo.file ? (
                    <Paper
                        variant="outlined"
                        style={{
                            backgroundColor: '#001e43',
                        }}
                    >
                        <video
                            style={{ margin: '5px' }}
                            id="videoBasic"
                            src={`https://pidaten.s3.amazonaws.com/static/${props.videoInfo.file}`}
                            controls
                            muted
                            loop
                            poster={`https://pidaten.s3.amazonaws.com/static/${props.videoInfo.thumbnail}`}
                        ></video>
                    </Paper>
                ) : (
                    <div>ビデオが見つかりません</div>
                )
            ) : null}

            {!!props.videoInfo.score.pitch > 0 ? (
                <div>
                    <div>
                        {isModalShown ? (
                            <AnalysisErrorModal
                                isModalOpen={isModalShown}
                                setIsModalOpen={setIsModalShown}
                            />
                        ) : null}
                        <PidatenScoreView
                            speed={props.videoInfo.score.speed * height}
                            pitch={(props.videoInfo.score.pitch * 1000) / 999}
                            stride={props.videoInfo.score.stride * height}
                        />
                        <TimeViewer
                            speed={props.videoInfo.score.speed * height}
                        />
                    </div>
                    <div>
                        <Button
                            style={{
                                backgroundColor: '#f8f8ff',
                                color: '#191970',
                            }}
                            onClick={handleModalOpen}
                        >
                            解析結果がおかしい場合はこちらを確認
                        </Button>
                    </div>
                </div>
            ) : (
                <div style={{ color: 'white' }}>
                    動画を解析中です。 少々お待ちください
                </div>
            )}
        </>
    );
};

const TimeViewer = (props) => {
    return (
        <div style={{ marginTop: '16px' }}>
            <table id="timeTable">
                <tbody>
                    <tr>
                        <th>100m 換算タイム</th>
                        <td>{estimateTime(props.speed)}</td>
                    </tr>
                    <tr>
                        <th>時速</th>
                        <td>{(props.speed * 3.6).toFixed(1)}&nbsp;km/h</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <br />
        </div>
    );
};

// function convertSecondToTime(num) {
//     var timeH = Math.floor((num % (24 * 60 * 60)) / (60 * 60));
//     var timeM = Math.floor(((num % (24 * 60 * 60)) % (60 * 60)) / 60);
//     var timeS = parseInt(((num % (24 * 60 * 60)) % (60 * 60)) % 60);
//     if (timeH < 1) {
//         if (timeM < 1) {
//             return timeS + '秒';
//         }
//         return timeM + '分' + timeS + '秒';
//     }
//     return timeH + '時間' + timeM + '分' + timeS + '秒';
// }
