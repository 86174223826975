import React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

export const CalibrationStepViewer = ({ activeStep }) => {
    const steps = ['動画選択', '実測値を入力', '送信'];

    return (
        <div
            style={{
                position: 'sticky',
                width: '100%',
                top: '0px',
                backgroundColor: '#27272efd',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    marginTop: '10px',
                    position: 'sticky',
                }}
            >
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        if (index === activeStep) {
                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    style={{ padding: '4px' }}
                                >
                                    <StepLabel>
                                        <Typography
                                            style={{
                                                color: '#bbe2f1',
                                                fontSize: '9pt',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {' '}
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            );
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>
                                    {' '}
                                    <Typography
                                        style={{
                                            fontSize: '9pt',
                                            fontWeight: 'bold',
                                            color: '#9fa09e',
                                        }}
                                    >
                                        {' '}
                                        {label}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </div>
    );
};
