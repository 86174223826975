import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Button,
    InputAdornment,
    IconButton,
    Input,
    TextField,
    InputLabel,
    FormControl,
    FormHelperText,
    Typography,
    MenuItem,
    Grid,
    Select,
    CircularProgress,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { sendRegisterInfo, sendLoginInfo } from '../../../v1apiRequest';
import { AuthContext } from '../../../App';
import classes from './register.module.css';

export const RegisterPage = (props) => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = React.useContext(AuthContext);
    const [accountInfo, setAccountInfo] = React.useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        height: '160',
        showPassword: false,
    });
    const [isUploading, setIsUploading] = React.useState(false);
    const [validationMessage, setValidationMessage] = React.useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        height: '',
    });
    const [isFormComplete, setIsFormComplete] = React.useState(false);

    React.useEffect(() => {
        const formValues = Object.values({
            ...accountInfo,
            showPassword: 'as',
        });
        const isAllFilled = formValues.every((f) => String(f).length > 0);
        const isNoValidation = Object.values(validationMessage).every(
            (f) => f.length == 0
        );

        if (isAllFilled && isNoValidation) {
            setIsFormComplete(true);
        } else setIsFormComplete(false);
    }, [accountInfo]);

    const handleInfoChange = (props) => (e) => {
        let _info = { ...accountInfo };
        _info[props] = e.target.value;
        setAccountInfo(_info);

        if (props === 'email') {
            let message = '';
            var reg =
                /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
            if (reg.test(e.target.value)) {
                setValidationMessage({
                    ...validationMessage,
                    email: '',
                });
            } else {
                message = 'emailアドレスが不適切です';
                setValidationMessage({
                    ...validationMessage,
                    email: message,
                });
            }
        }

        if (props === 'password') {
            if (e.target.value.length <= 5) {
                setValidationMessage({
                    ...validationMessage,
                    password: 'パスワードは6文字以上入力してください',
                });
            } else if (
                //confirmPasswordが入力された後に再度PWが変更されたら

                e.target.value !== accountInfo.confirmPassword &&
                accountInfo.confirmPassword.length > 0
            ) {
                setValidationMessage({
                    ...validationMessage,
                    ...{
                        confirmPassword: 'パスワードが一致していません',
                        password: '',
                    },
                });
            } else {
                setValidationMessage({
                    ...validationMessage,
                    password: '',
                });
            }
        }
        if (props === 'confirmPassword') {
            let message = '';
            if (e.target.value === accountInfo.password) {
                setValidationMessage({
                    ...validationMessage,
                    confirmPassword: '',
                });
            } else {
                message = 'パスワードが一致していません';
                setValidationMessage({
                    ...validationMessage,
                    confirmPassword: message,
                });
            }
        }
    };

    const handleSendClick = () => {
        const sendInfo = {
            ...accountInfo,
            last_name: 'hoge',
            first_name: 'fuga',
        };
        console.log(sendInfo);
        setIsUploading(true);
        sendRegisterInfo(sendInfo)
            .then((res) => {
                //alert('account created');
                console.log(res.data);
                // 今度はここからトークンを取りに行く処理
                sendLoginInfo({
                    password: accountInfo.password,
                    email: accountInfo.email,
                })
                    .then((res) => {
                        console.log(res.data);
                        localStorage.setItem('access', res.data.token);
                        setIsAuthenticated(true);
                        alert('ようこそ！');
                        setIsUploading(false);
                        history.push('/');
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsUploading(false);
            });
    };

    const handleClickShowPassword = () => {
        setAccountInfo({
            ...accountInfo,
            showPassword: !accountInfo.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (e) => {
        let _info = { ...accountInfo };
        _info['password'] = e.target.value;
        setAccountInfo(_info);
    };

    const gotoLogin = () => {
        history.push('/login');
    };

    return (
        <div style={{ textAlign: 'center' }}>
            新規登録
            <div>
                <TextField
                    style={{ marginTop: '20px', width: '70%' }}
                    value={accountInfo.username}
                    label="ユーザー名"
                    onChange={handleInfoChange('username')}
                    helperText={validationMessage.username}
                    placeholder="（例）山田 太郎"
                />
                <TextField
                    style={{ marginTop: '20px', width: '70%' }}
                    value={accountInfo.email}
                    label="email"
                    onChange={handleInfoChange('email')}
                    helperText={validationMessage.email}
                />
                <div style={{ marginTop: '20px' }}>
                    <Grid container style={{ width: '70%', margin: 'auto' }}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: '80%', color: 'black' }}
                                select
                                label="性別"
                                value={accountInfo.gender}
                                onChange={handleInfoChange('gender')}
                            >
                                {['男性', '女性'].map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        style={{ color: '#222222' }}
                                        className={classes.select}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '80%' }}>
                                <InputLabel>身長（cm）</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={accountInfo.height}
                                    label="Age"
                                    defaultValue={160}
                                    onChange={handleInfoChange('height')}
                                    className={classes.select}
                                >
                                    {[...Array(81).keys()].map((_, idx) => {
                                        return (
                                            <MenuItem key={_} value={idx + 130}>
                                                {idx + 130}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <FormControl style={{ width: '70%' }}>
                    <InputLabel htmlFor="standard-adornment-password">
                        パスワード
                    </InputLabel>
                    <Input
                        id="standard-adornment-password"
                        value={accountInfo.password}
                        type={accountInfo.showPassword ? 'text' : 'password'}
                        onChange={handleInfoChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {accountInfo.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText
                        id="standard-adornment-password"
                        style={{ color: 'red' }}
                    >
                        {validationMessage.password}
                    </FormHelperText>
                </FormControl>

                <FormControl style={{ width: '70%', marginTop: '5%' }}>
                    <InputLabel htmlFor="standard-adornment-password">
                        パスワード再入力
                    </InputLabel>
                    <Input
                        id="standard-adornment-password2"
                        value={accountInfo.confirmPassword}
                        type={accountInfo.showPassword ? 'text' : 'password'}
                        onChange={handleInfoChange('confirmPassword')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {accountInfo.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText
                        id="standard-adornment-password2"
                        style={{ color: 'red' }}
                    >
                        {validationMessage.confirmPassword}
                    </FormHelperText>
                </FormControl>
            </div>
            {isFormComplete ? (
                isUploading ? (
                    <Button
                        variant="contained"
                        disabled
                        style={{ marginTop: '30px' }}
                    >
                        登録 <CircularProgress size={20} />
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '30px' }}
                        onClick={handleSendClick}
                    >
                        登録
                    </Button>
                )
            ) : (
                <Button
                    variant="contained"
                    disabled
                    style={{ marginTop: '30px' }}
                >
                    登録
                </Button>
            )}
            <div style={{ marginTop: '30px' }}>
                <Typography style={{ fontSize: '9pt' }}>
                    アカウントをお持ちの方は
                    <span className="textlink" onClick={gotoLogin}>
                        ログイン
                    </span>
                    画面へ
                </Typography>
            </div>
        </div>
    );
};

const CustomTextInput = (props) => {
    const labelName = props.labelName;
    const infoToChange = props.infoToChange;
    const value = props.value;
    const handleInfoChange = props.handleInfoChange;
    return props.valid ? (
        <TextField
            style={{ marginTop: '20px', width: '70%' }}
            value={value}
            label={labelName}
            onChange={handleInfoChange(infoToChange)}
        />
    ) : (
        <TextField
            error
            style={{ marginTop: '20px', width: '70%' }}
            value={value}
            label={labelName}
            onChange={handleInfoChange(infoToChange)}
        />
    );
};
