import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';

import { sendLoginInfo } from '../v1apiRequest';
import { AuthContext } from '../App';

export const LogOutPage = (props) => {
    const history = useHistory();

    const [isAuthenticated, setIsAuthenticated] = React.useContext(AuthContext);
    const [isUploading, setIsUploading] = React.useState(false);

    const handleLogout = () => {
        localStorage.setItem('access', 'undefined');
        setIsAuthenticated(false);
        history.push(`/login`);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {isUploading ? (
                <Button
                    variant="contained"
                    disabled
                    style={{ marginTop: '30px' }}
                >
                    ログアウト <CircularProgress size={20} />
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '30px' }}
                    onClick={handleLogout}
                >
                    ログアウト
                </Button>
            )}
        </div>
    );
};
