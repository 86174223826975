import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
    Button,
    TextField,
    Grid,
    Card,
    MenuItem,
    CircularProgress,
} from '@material-ui/core';
import { AccountContext } from '../../App';

import '../../index.css';
import '../../App.css';
import { getAllVideo, postManualScore } from '../../v1apiRequest';
import { formatDate, sortVideoByDate } from './videoListPage/VideoListPage';

export const AdminPage = () => {
    const [searchWord, setSearchWord] = React.useState('');
    const [videoList, setVideoList] = React.useState([]);
    const [chosenVideo, setChosenVideo] = React.useState({});

    const handleChange = (e) => {
        setSearchWord(e.target.value);
    };
    const handleSearch = () => {
        let _videos = videoList.filter((f) => {
            f.id.indexOf(searchWord) || f.file.indexOf(searchWord);
            return f;
        });
        setChosenVideo(_videos[0]);
    };

    const onClickVideo = (prop) => () => {
        console.log(prop);
        setChosenVideo(prop);

        axios
            .get(`https://pidaten.s3.amazonaws.com/result/${prop.file}.json`)
            .then((res) => {
                const height = parseInt(prop.uploaded_by.height);
                console.log(res.data);
                console.log('速度', height * res.data.speed);
                console.log('ストライド', height * res.data.stride);
            })
            .catch((res) => {
                console.log('miss!!');
            });
    };

    React.useEffect(() => {
        getAllVideo().then((res) => {
            console.log(res.data);
            setVideoList(sortVideoByDate(res.data));
        });
    }, []);

    return (
        <div
            style={{
                margin: '3px',
                overflowY: 'scroll',
                border: '3px solid #EEEEEE',
                backgroundColor: 'white',
            }}
        >
            <p>管理ページ</p>
            <TextField
                variant="outlined"
                label="ユーザーIDまたはビデオID"
                style={{ width: '95%', marginLeft: '10px' }}
                multiline
                value={searchWord}
                onChange={handleChange}
            />
            <div style={{ padding: '10px' }}>
                <Button variant="outlined" onClick={handleSearch}>
                    検索
                </Button>
            </div>

            <div
                style={{
                    margin: '3px',
                    overflowY: 'scroll',
                    height: `${window.parent.screen.height * 0.6}px`,
                    border: '3px solid #EEEEEE',
                }}
            >
                {videoList && videoList.length > 0
                    ? videoList.map((vObj, idx) => {
                          return (
                              <VideoCardAdmin
                                  key={idx}
                                  video={vObj}
                                  idx={idx}
                                  onClickFunc={onClickVideo}
                              />
                          );
                      })
                    : null}
            </div>
            <div>
                {chosenVideo.file ? (
                    <video
                        src={`https://pidaten.s3.amazonaws.com/static/${chosenVideo.file}`}
                        controls
                        style={{ width: '80%' }}
                        muted
                        loop
                        poster={`https://pidaten.s3.amazonaws.com/static/${chosenVideo.thumbnail}`}
                    ></video>
                ) : null}
            </div>

            <br />
        </div>
    );
};

export const VideoCardAdmin = (props) => {
    const analysisStatus = props.video.analysis_status;
    const score = props.video.score;

    return (
        <div variant="outlined" id="videoCard">
            <Grid container>
                <Grid item xs={4}>
                    <div onClick={props.onClickFunc(props.video)}>
                        <img
                            alt=""
                            width="90%"
                            src={`https://pidaten.s3.amazonaws.com/static/${props.video.thumbnail}`}
                        ></img>
                    </div>
                </Grid>

                <Grid item xs={8}>
                    <div style={{ textAlign: 'left' }}>
                        <div style={{ fontSize: '10pt', display: 'flex' }}>
                            <div>
                                投稿日:{formatDate(props.video.uploaded_date)}
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                {/* マニュアル制御の時はこっち */}
                                {analysisStatus ? (
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            backgroundColor: '#33CC33',
                                            color: 'white',
                                            fontSize: '8pt',
                                            borderRadius: '10px',
                                            padding: '1px',
                                        }}
                                    >
                                        &nbsp;解析完了&nbsp;
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '8pt',
                                            borderRadius: '10px',
                                            backgroundColor: '#c0c0c0',
                                        }}
                                    >
                                        &nbsp;解析待ち&nbsp;
                                    </span>
                                )}
                            </div>
                            <div style={{ marginLeft: '3%' }}>
                                局面:{props.video.phase}
                            </div>
                        </div>
                        <div style={{ fontSize: '10pt' }}>
                            投稿者（ランナー）:
                            {props.video.upload_runner.runner_name} <br />
                            アカウント:
                            {props.video.uploaded_by.username}{' '}
                        </div>
                        <div style={{ fontSize: '10pt' }}>
                            {!!score ? (
                                <>
                                    速度:
                                    {Number(
                                        score.speed *
                                            props.video.upload_runner
                                                .calibrated_height
                                    ).toFixed(2)}
                                    , ピッチ: {Number(score.pitch).toFixed(2)},
                                    ストライド:{' '}
                                    {Number(
                                        score.stride *
                                            props.video.upload_runner
                                                .calibrated_height
                                    ).toFixed(2)}
                                </>
                            ) : null}
                        </div>
                        <div style={{ fontSize: '8pt' }}>
                            ファイルパス:{props.video.file}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
