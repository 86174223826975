import React from 'react';
import { Modal, Fade, Backdrop, Box, Grid } from '@mui/material';
import { style } from './style';
import { GridView } from '../../../videoListPage/VideoListPage';
import { VideoPaginationViewer } from '../../../videoListPage/components/VideoPaginationViewer';
import { SearchBar } from '../../../../../utils/SearchBar';
import { SearchByTag } from './SearchByTag';

export const VideoListModal = (props) => {
    const handleClose = () => {
        props.setIsModalOpen(false);
    };

    const [videoToShow, setVideoToShow] = React.useState([]); //抱えてる全件数
    const [searchWord, setSearchWord] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [phase, setPhase] = React.useState('None');

    const handleChangeTag = (event) => {
        setPhase(event.target.value);
        var result = props.videos.filter(function (video) {
            return video.phase.indexOf(event.target.value) !== -1;
        });
        setVideoToShow(result);
    };

    const handleSearch = () => {
        var result = videoToShow.filter(function (video) {
            return video.memo.indexOf(searchWord) !== -1;
        });
        console.log(result);
        setVideoToShow(result);
    };

    const handleChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    };

    // videoがfetchされた時に発火
    React.useEffect(() => {
        console.log(videoToShow);
        console.log(props.videos);
        if (props.videos === videoToShow) {
            return;
        }
        if (props.videos.length > 0) {
            console.log('set');
            var result = props.videos.filter(function (video) {
                return video.phase.indexOf(props.currentSelected.phase) !== -1;
            });
            setVideoToShow(result);
            if (props.currentSelected.phase === 'None') {
                console.log(props.currentSelected.phase === 'None');
                setPhase('');
            } else {
                setPhase(props.currentSelected.phase);
            }
        }
        return;
    }, [props.videos]);

    const maxPages = parseInt((videoToShow.length - 1) / 30 + 1);

    //表示するビデオの制御
    const maxLen = videoToShow.length;
    const start = parseInt((currentPage - 1) * 30);
    const finish =
        currentPage * 30 > maxLen ? maxLen : parseInt(currentPage * 30);

    const videoShown = videoToShow.slice(start, finish);

    const handleForwardClick = () => {
        if (maxPages > currentPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleBackwardClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    console.log(videoToShow);

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.paper}>
                        <div
                            style={{
                                color: 'white',
                                fontSize: '12pt',
                                fontWeight: 'bold',
                                padding: '8px',
                            }}
                        >
                            比較する動画を選択
                        </div>
                        <Grid container>
                            <Grid item xs={7}>
                                <SearchBar
                                    label="メモから動画を検索"
                                    placeholder="（例）流し"
                                    onIconClick={handleSearch}
                                    func={handleChangeSearchWord}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <SearchByTag
                                    phase={phase}
                                    handleChangeTag={handleChangeTag}
                                />
                            </Grid>
                        </Grid>
                        {videoToShow.length > 0 ? (
                            <VideoPaginationViewer
                                handleForwardClick={handleForwardClick}
                                handleBackwardClick={handleBackwardClick}
                                currentPage={currentPage}
                                maxPages={maxPages}
                                maxLen={videoToShow.length}
                            />
                        ) : (
                            <></>
                        )}

                        <div id="transition-modal-description">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <GridView
                                    video={videoShown}
                                    handleClick={props.handleSetVideo}
                                    currentSelected={props.currentSelected}
                                />
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
