import React from 'react';

import { Grid } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { estimateTime } from '../../func/estimate_100time';

export const PidatenScoreView = (props) => {
    const estTime = estimateTime(props.speed);

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <span
                                style={{
                                    color: 'aquamarine',
                                    fontWeight: 'bold',
                                    fontSize: '10pt',
                                }}
                            >
                                100m推定タイム
                            </span>
                            <PidatenTimeView time={estTime} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <span
                                style={{
                                    color: 'aquamarine',
                                    fontWeight: 'bold',
                                    fontSize: '10pt',
                                }}
                            >
                                平均速度 (m/s)
                            </span>
                            <PidatenSpeedView speed={props.speed} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Grid container style={{ marginTop: '18px' }}>
                <Grid item xs={6}>
                    <div>
                        <span
                            style={{
                                color: '#66ccff',
                                fontWeight: 'bold',
                                fontSize: '9pt',
                            }}
                        >
                            ピッチ (歩/s)
                        </span>
                        <PidatenMinorView
                            value={props.pitch}
                            labelName={'pitch'}
                            gcolor={'#66CCFF'}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <span
                            style={{
                                color: '#ffd700',
                                fontWeight: 'bold',
                                fontSize: '9pt',
                            }}
                        >
                            ストライド (m)
                        </span>
                        <PidatenMinorView
                            value={props.stride}
                            labelName={'stride'}
                            gcolor={'#FFCC00'}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export const PidatenSpeedView = (props) => {
    const speedPercent = (props.speed / 13) * 100;
    console.log(props.speed);
    const state = {
        series: [speedPercent],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -10,
                sparkline: {
                    enabled: true,
                },
            },
            plotOptions: {
                radialBar: {
                    startAngle: -110,
                    endAngle: 110,
                    track: {
                        background: '#e7e7e7', //'#e7e7e7',
                        strokeWidth: '97%',
                        margin: 4, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2,
                        },
                    },
                    dataLabels: {
                        name: {
                            show: false,
                            offsetY: -20,
                            fontSize: '12px',
                        },
                        value: {
                            offsetY: -0,
                            fontSize: '28px',
                            color: '#AAFFFF',
                            formatter: function (val) {
                                const speed = ((val / 100) * 13).toFixed(2);
                                return speed;
                            },
                        },
                    },
                },
            },
            grid: {
                padding: {
                    top: -10,
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 53, 91],
                    gradientToColors: ['#ABE5A1'],
                },
            },
            labels: ['Speed (m/s)'],
        },
    };

    return (
        <>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="radialBar"
                height={160}
            />
        </>
    );
};

export const PidatenMinorView = ({ value, labelName, gcolor }) => {
    let percent = 0;
    console.log(value);
    console.log(labelName);
    if (labelName === 'pitch') {
        percent = (value / 6.0) * 100;
        console.log(percent);
    }
    if (labelName === 'stride') {
        percent = (value / 2.5) * 100;
        console.log(percent);
    }
    const state = {
        series: [percent],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -10,
                sparkline: {
                    enabled: true,
                },
            },
            plotOptions: {
                radialBar: {
                    startAngle: -110,
                    endAngle: 110,
                    track: {
                        background: '#e7e7e7', //'#e7e7e7',
                        strokeWidth: '97%',
                        margin: 4, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2,
                        },
                    },
                    dataLabels: {
                        name: {
                            show: false,
                            offsetY: -20,
                            fontSize: '10px',
                        },
                        value: {
                            offsetY: -0,
                            fontSize: '26px',
                            color: '#AAFFFF',
                            formatter: function (val) {
                                if (labelName === 'pitch')
                                    return ((val / 100) * 6.0).toFixed(2);
                                if (labelName === 'stride')
                                    return ((val / 100) * 2.5).toFixed(2);
                            },
                        },
                    },
                },
            },
            grid: {
                padding: {
                    top: -10,
                },
            },
            fill: {
                type: 'gradient',

                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 10],
                    gradientToColors: [gcolor],
                },
            },
            labels: ['Speed (m/s)'],
        },
    };

    return (
        <>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="radialBar"
                height={150}
            />
        </>
    );
};

export const PidatenTimeView = ({ time }) => {
    const before = time.split('秒')[0];
    const aftr = time.split('秒')[1];
    console.log(before, aftr);
    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <span id="timeChar">{before}</span>
                <span id="timeChar">"</span>
                <span id="timeChar">{aftr}</span>
            </div>
        </>
    );
};
