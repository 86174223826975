import React from 'react';
import { Modal, Fade, Backdrop, Box, Grid } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import { style } from './style';
import { GridView } from '../../videoListPage/VideoListPage';
import { VideoPaginationViewer } from '../../videoListPage/components/VideoPaginationViewer';
import { SearchBar } from '../../../../utils/SearchBar';
import { useGetVideoByRunner } from '../../../../hooks/useGetVideoByRunner';
import { AccountContext } from '../../../../App';

export const VideoCalibModal = (props) => {
    const handleClose = () => {
        props.setIsModalOpen(false);
    };
    const { currentRunner } = React.useContext(AccountContext);

    const { videos, isVideoLoading } = useGetVideoByRunner(currentRunner.id);

    const [videoToShow, setVideoToShow] = React.useState([]); //抱えてる全件数
    const [searchWord, setSearchWord] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);

    const handleSearch = () => {
        var result = videoToShow.filter(function (video) {
            return video.memo.indexOf(searchWord) !== -1;
        });
        console.log(result);
        setVideoToShow(result);
    };

    const handleChangeSearchWord = (e) => {
        setSearchWord(e.target.value);
    };

    // videoがfetchされた時に発火
    React.useEffect(() => {
        console.log(videos);
        var result = videos.filter(function (video) {
            return (
                video.phase.indexOf('middle') !== -1 &&
                !!video.score &&
                !isNaN(video.score.speed)
            );
        });
        setVideoToShow(result);
    }, [videos]);

    const maxPages = parseInt((videoToShow.length - 1) / 30 + 1);

    //表示するビデオの制御
    const maxLen = videoToShow.length;
    const start = parseInt((currentPage - 1) * 30);
    const finish =
        currentPage * 30 > maxLen ? maxLen : parseInt(currentPage * 30);

    const videoShown = videoToShow.slice(start, finish);

    const handleForwardClick = () => {
        if (maxPages > currentPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleBackwardClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    console.log(videoToShow);

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.paper}>
                        <div
                            style={{
                                color: 'white',
                                fontSize: '12pt',
                                fontWeight: 'bold',
                                padding: '8px',
                            }}
                        >
                            補正に使う動画を選択
                        </div>

                        <SearchBar
                            label="メモから動画を検索"
                            placeholder="（例）流し"
                            onIconClick={handleSearch}
                            func={handleChangeSearchWord}
                        />

                        {isVideoLoading ? (
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress
                                    style={{ color: '#00984f' }}
                                />
                                <div style={{ color: 'white' }}>
                                    ビデオをロード中
                                </div>
                            </div>
                        ) : (
                            <>
                                <div>
                                    {videoToShow.length > 0 ? (
                                        <VideoPaginationViewer
                                            handleForwardClick={
                                                handleForwardClick
                                            }
                                            handleBackwardClick={
                                                handleBackwardClick
                                            }
                                            currentPage={currentPage}
                                            maxPages={maxPages}
                                            maxLen={videoToShow.length}
                                        />
                                    ) : null}
                                </div>

                                <div id="transition-modal-description">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <GridView
                                            video={videoShown}
                                            handleClick={props.handleSetVideo}
                                            currentSelected={
                                                props.currentSelected
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
