import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { VideoContext, AccountContext } from '../App';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export const UploadNotification = (props) => {
    const classes = useStyles();
    const { setVideoSentStatus } = React.useContext(VideoContext);

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setVideoSentStatus({ open: false, status: 'yet' });
    };

    return (
        <div className={classes.root}>
            <Snackbar
                open={props.status.open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                {props.status.status === 'OK' ? (
                    <Alert onClose={handleClose} severity="success">
                        アップロードが成功しました！{' '}
                    </Alert>
                ) : props.status.status === 'ERROR' ? (
                    <Alert onClose={handleClose} severity="warning">
                        アップロードに失敗しました{' '}
                    </Alert>
                ) : props.status.status === 'LONG' ? (
                    <Alert onClose={handleClose} severity="warning">
                        ビデオのフレーム数が多すぎます（最大1,000フレームまで送信可能）{' '}
                    </Alert>
                ) : null}
            </Snackbar>
        </div>
    );
};
