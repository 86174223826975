import React from 'react';
import Card from '@material-ui/core/Typography';

import { ScoreView } from './ScoreView';

export const AfterFirstLogin = ({ currentRunner, handleClick }) => {
    return (
        <div>
            <p style={{ color: 'white' }}>
                ようこそ、{currentRunner.runner_name}さん！
            </p>
            <div style={{ textAlign: 'left', padding: '10px' }}>
                <div style={{ color: 'white', fontSize: '11pt' }}>
                    まずは{' '}
                    <span onClick={handleClick} id="linkedText">
                        動画追加
                    </span>{' '}
                    から走りを解析してみましょう！
                </div>
                <div
                    style={{
                        color: 'white',
                        fontSize: '11pt',
                        marginTop: '12px',
                    }}
                >
                    初めての撮影の際は、下記の
                    <span
                        style={{
                            color: '#ffd700',
                            fontSize: '11pt',
                            fontWeight: 'bold',
                        }}
                    >
                        撮影マニュアル動画
                    </span>
                    を一度ご覧になってから撮影に入って下さると助かります。
                    <Card
                        style={{
                            padding: '2px',
                            backgroundColor: '#fff',
                            width: '99%',
                            marginTop: '10px',
                        }}
                    >
                        <iframe
                            width="99%"
                            height="70%"
                            src={
                                'https://www.youtube.com/embed/f6YQHKkDKzQ' +
                                '?rel=0&modestbranding=1'
                            }
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ marginBottom: '8px' }}
                        ></iframe>
                    </Card>
                </div>
            </div>
        </div>
    );
};
