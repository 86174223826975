import React from 'react';

import { useHistory } from 'react-router-dom';
import { AccountContext } from '../../../App';
import { getAccountInfo } from '../../../v1apiRequest';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetVideo } from '../../../hooks/useGetVideos';
import { useGetVideoByRunner } from '../../../hooks/useGetVideoByRunner';
import { sortVideoByDate } from '../videoListPage/VideoListPage';
import { Announce } from './Announce';
import { Keijiban } from './Keijiban';
import { AfterFirstLogin } from './AfterFirstLogin';
import { Paper, Card } from '@material-ui/core';
import { SingleVideoCard } from '../videoListPage/components/SingleVideoCard';

export const MemberHomePage = () => {
    const { accountInfo, setAccountInfo, currentRunner, setCurrentRunner } =
        React.useContext(AccountContext);
    const { videos, isVideoLoading } = useGetVideoByRunner(currentRunner.id);
    console.log(isVideoLoading, accountInfo, currentRunner);

    // 1番最初に1回だけ呼ばれる
    React.useEffect(() => {
        console.log('ホーム');
        const curRunner = localStorage.getItem('currentRunner');

        getAccountInfo()
            .then((res) => {
                console.log('account=', res.data);
                setAccountInfo(res.data);
                // 続いて、localStorageを参照して適切なrunnerをセット

                if (curRunner && curRunner.split('').length > 11) {
                    // 要は保存されてたら、ということ
                    const prevRunner = searchRunnerById(
                        curRunner,
                        res.data.runners
                    );
                    console.log('prevRunner=', prevRunner);
                    console.log('curRunner=', curRunner);

                    setCurrentRunner(prevRunner);
                } else {
                    setCurrentRunner(res.data.runners[0]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div style={{ textAlign: 'center' }}>
            <div
                style={{
                    textAlign: 'center',
                    padding: '5px',
                    overflowY: 'scroll',
                }}
            >
                <Announce />
                {isVideoLoading ? (
                    <div>
                        <div style={{ color: 'white' }}>
                            Now Loading
                            <CircularProgress color="success" />
                        </div>
                    </div>
                ) : (
                    <RecentVideoList
                        videos={sortVideoByDate(videos)}
                        accountInfo={accountInfo}
                        currentRunner={currentRunner}
                    />
                )}
                <br />

                <Keijiban />

                <br />
            </div>
        </div>
    );
};

export const RecentVideoList = ({ videos, accountInfo, currentRunner }) => {
    const history = useHistory();
    const handleUploadClick = () => {
        history.push(`/upload`);
    };

    if (videos.length < 1) {
        return (
            <AfterFirstLogin
                currentRunner={currentRunner}
                handleClick={handleUploadClick}
            />
        );
    }

    const changeVideoId = (video) => () => {
        if (video.id) {
            history.push(`/result/${video.id}`);
        }
    };

    return (
        <div>
            <span style={{ fontWeight: 'bold', color: '#f7f6f5' }}>
                {currentRunner.runner_name}
            </span>
            <span style={{ color: '#f7f6f5' }}>&nbsp;さんの最新の動画</span>
            <Card
                style={{
                    backgroundColor: '#022130',
                    width: '95%',
                    marginLeft: '2%',
                }}
            >
                {videos.slice(0, 3).map((video, idx) => {
                    return (
                        <SingleVideoCard
                            key={idx}
                            video={video}
                            idx={idx}
                            handleClick={changeVideoId}
                            hideDeleteButton={true}
                        />
                    );
                })}
                <br />
                <br />
            </Card>
        </div>
    );
};

function searchRunnerById(rId, rList) {
    for (const runner of rList) {
        if (String(runner.id) === String(rId)) {
            return runner;
        }
        continue;
    }
    return false;
}
