import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
        marginLeft: '2%',
        backgroundColor: '#efefef',
        borderRadius: '18px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    background: {
        backgroundColor: '#efefef',
    },
}));

export const Keijiban = () => {
    const classes = useStyles();

    const a = 'a';
    return (
        <>
            <div
                style={{
                    textAlign: 'left',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    margin: '10px',
                    color: '#cccccc',
                }}
            >
                よくある質問
            </div>
            <div className={classes.root}>
                <Accordion className={classes.background}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>
                            動画の撮り方について
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '1px' }}>
                        <Card
                            style={{
                                padding: '2px',
                                backgroundColor: '#fff',
                                width: '99%',
                                marginBottom: '10px',
                            }}
                        >
                            <ul style={{ textAlign: 'left', fontSize: '11pt' }}>
                                <li>
                                    カメラを固定し、被写体を追わずに撮影する
                                </li>
                                <li>スロー（120fps推奨）で撮影する</li>
                                <li>
                                    被写体より手前に他の人を極力映さないようにする
                                </li>
                            </ul>
                            <iframe
                                width="99%"
                                height="60%"
                                src={
                                    'https://www.youtube.com/embed/f6YQHKkDKzQ' +
                                    '?rel=0&modestbranding=1'
                                }
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ marginBottom: '8px' }}
                            ></iframe>
                        </Card>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.background}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            マニュアルを見る
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ textAlign: 'left' }}>
                            使い方やマニュアルは
                            <a
                                rel="noreferrer"
                                href="https://detailed-jaborosa-b22.notion.site/IDATEN-54e20e26159b4e618b7718c908df8139"
                                target="_blank"
                            >
                                こちらのリンク
                            </a>
                            からご確認ください。
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};
