import React from 'react';
import { Button, Modal, Fade, Backdrop, Box } from '@mui/material';
import { style } from './style';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const LowFPSModal = (props) => {
    const handleClose = () => {
        props.closeFunc();
    };

    return (
        <div>
            <Modal
                className={style.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isModalOpen}>
                    <Box sx={style.fpsAlert}>
                        <ErrorOutlineIcon
                            size="medium"
                            style={{ color: '#fcc800' }}
                        />
                        <div style={{ margin: '7px', fontWeight: 'bold' }}>
                            {' '}
                            fpsが低いかもしれません
                        </div>
                        <div style={{ fontSize: '10pt', textAlign: 'left' }}>
                            当アプリでは、120fps以上のスロー再生を推奨しております。動画の解析結果がおかしい場合は下記の事項をご確認ください。
                            <ul>
                                <li>スロー撮影になっているか</li>
                                <li>スローと通常速度が混ざっていないか</li>
                                <li>動画のタグと速度が合っているか</li>
                            </ul>
                        </div>
                        <div id="transition-modal-description">
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '16px',
                                }}
                            >
                                <Button
                                    onClick={props.closeFunc}
                                    variant="outlined"
                                >
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
