import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import '../../App.css';
import HouseIcon from '@material-ui/icons/House';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

import BackupIcon from '@mui/icons-material/Backup';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
// import { AppContext } from './router/Router';
import { Grid, CardActionArea } from '@material-ui/core';
import { UploadFAB } from '../common/FAB/FloatActionButton';

export const FooterComponent = () => {
    const history = useHistory();
    const location = useLocation();

    const [currentPath, setCurrentPath] = React.useState(
        history.location.pathname
    );
    React.useEffect(() => {
        console.log(history.location.pathname);
        console.log(location.pathname);
    }, [history.location.pathname, location]);

    const handleHomeClick = () => {
        setCurrentPath('/');
        history.push(`/`);
    };
    const handleUploadClick = () => {
        history.push(`/upload`);
        setCurrentPath('/upload');
    };

    const handleAnalyzeClick = () => {
        history.push(`/analyze`);
        setCurrentPath('/analyze');
    };

    const handleVideoList = () => {
        history.push(`/videolist`);
        setCurrentPath('/videolist');
    };

    const handleMyPage = () => {
        history.push(`/mypage`);
        setCurrentPath('/mypage');
    };

    return (
        <div id="footer">
            <UploadFAB goToUploadPage={handleUploadClick} />

            <Grid container>
                <Grid item xs={3}>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                        onClick={handleHomeClick}
                    >
                        <CardActionArea>
                            {currentPath === '/' ? (
                                <HouseIcon
                                    fontSize="large"
                                    style={{ color: '#87cefa' }}
                                />
                            ) : (
                                <HouseOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#a9a9a9' }}
                                />
                            )}
                        </CardActionArea>
                    </div>
                </Grid>

                <Grid item xs={3}>
                    <div
                        style={{ textAlign: 'center' }}
                        onClick={handleAnalyzeClick}
                    >
                        <CardActionArea>
                            {currentPath === '/analyze' ? (
                                <TimelineOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#87cefa' }}
                                />
                            ) : (
                                <TimelineOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#a9a9a9' }}
                                />
                            )}
                        </CardActionArea>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div
                        style={{ textAlign: 'center' }}
                        onClick={handleVideoList}
                    >
                        <CardActionArea>
                            {currentPath === '/videolist' ? (
                                <PhotoLibraryIcon
                                    fontSize="large"
                                    style={{ color: '#87cefa' }}
                                />
                            ) : (
                                <PhotoLibraryOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#a9a9a9' }}
                                />
                            )}
                        </CardActionArea>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ textAlign: 'center' }} onClick={handleMyPage}>
                        <CardActionArea>
                            {currentPath === '/mypage' ? (
                                <SettingsIcon
                                    fontSize="large"
                                    style={{ color: '#87cefa' }}
                                />
                            ) : (
                                <SettingsOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#a9a9a9' }}
                                />
                            )}
                        </CardActionArea>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const HeaderComponent = () => {
    return (
        <div
            style={{
                textAlign: 'center',
                backgroundColor: '#99ccff',
                padding: '6px',
                marginBottom: '15px',
            }}
        >
            IDATEN
        </div>
    );
};
