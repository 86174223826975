import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Button, Modal, Fade, Backdrop, TextField } from '@material-ui/core';
import { AccountContext } from '../../../App';
import { TabInfo } from '../ResultPage/TabInfo';

import '../../../index.css';
import '../../../App.css';
import { putAccountInfo, putRunnerInfo } from '../../../v1apiRequest';
import { PageTitle } from '../../common/PageTitle';
import { HeightSelecter } from '../../../utils/HeightSelecter';
import { CalibrationView } from './CalibrationView';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#EEEEEE',
        border: '2px solid #0033CC',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
export const UserAccountPage = () => {
    const history = useHistory();

    const classes = useStyles();

    const { accountInfo, setAccountInfo, currentRunner, setCurrentRunner } =
        React.useContext(AccountContext);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [runnerInfoEdit, setRunnerInfoEdit] = React.useState(currentRunner);

    React.useEffect(() => {
        console.log('アカウントページ');
    }, []);

    const handleEditProfile = () => {
        var _isEditing = !isEditing;
        setRunnerInfoEdit(currentRunner);
        setIsEditing(_isEditing);
    };

    const handleChange = (prop) => (e) => {
        let _info = { ...runnerInfoEdit };
        _info[prop] = e.target.value;
        setRunnerInfoEdit(_info);
    };

    const handleSaveOrCancel = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEditing(false);
    };

    const handleUpadateProfile = () => {
        console.log(runnerInfoEdit);
        setIsUploading(true);
        putRunnerInfo(runnerInfoEdit)
            .then((res) => {
                console.log(res);
                setCurrentRunner(res.data);
                history.push('/');
            })
            .catch((e) => {
                console.log(e);
            });
        setIsModalOpen(false);
        setIsEditing(false);
    };

    const accountLables = ['プロフィール', '精度調整'];
    return (
        <div
            style={{
                textAlign: 'center',
                width: '99%',
                overflowY: 'scroll',
                height: `${window.parent.screen.height * 0.85}px`,
            }}
        >
            <PageTitle title={'詳細設定ページ'} />

            <div>
                <Modal
                    className={classes.modal}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isModalOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={isModalOpen}>
                        <div className={classes.paper}>
                            <h3 id="transition-modal-title">
                                このランナーの情報を更新しますか？
                            </h3>
                            <div id="transition-modal-description">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        onClick={handleCancel}
                                        variant="outlined"
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        onClick={handleUpadateProfile}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        変更を保存
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <TabInfo labels={accountLables} bgColor="#444444">
                    <ProfileView
                        accountInfo={accountInfo}
                        handleSaveOrCancel={handleSaveOrCancel}
                        handleEditProfile={handleEditProfile}
                        isEditing={isEditing}
                        handleChange={handleChange}
                        currentRunner={currentRunner}
                        runnerInfoEdit={runnerInfoEdit}
                    />
                    <CalibrationView />
                </TabInfo>
            </div>
            {/* <Button onClick={handleTest}>追加</Button> */}
        </div>
    );
};

const ProfileView = (props) => {
    const account = props.accountInfo;

    return (
        <div style={{ padding: '10px' }}>
            <div>
                <div style={{ textAlign: 'right' }}>
                    {props.isEditing ? (
                        <Button
                            size="small"
                            variant="outlined"
                            style={{
                                color: '#99ccff',
                            }}
                            onClick={props.handleSaveOrCancel}
                        >
                            変更保存/キャンセル
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: '#99ccff',
                                color: '#000',
                            }}
                            onClick={props.handleEditProfile}
                        >
                            ランナー情報を編集
                        </Button>
                    )}
                </div>
            </div>

            <div style={{ marginTop: '20px' }}>
                <table id="accountTable">
                    <tbody>
                        <tr>
                            <th>親アカウント名</th>
                            <td>{account.username}</td>
                        </tr>

                        <tr>
                            <th>email</th>
                            <td>{account.email}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <>
                {props.isEditing ? (
                    <div style={{ marginTop: '20px' }}>
                        <table id="accountTable">
                            <tbody>
                                <tr>
                                    <th>ランナー名</th>
                                    <td>
                                        {' '}
                                        <TextField
                                            InputProps={{
                                                style: {
                                                    backgroundColor: '#202020',
                                                    paddingLeft: '10px',
                                                    color: 'white',
                                                },
                                            }}
                                            onChange={props.handleChange(
                                                'runner_name'
                                            )}
                                            value={
                                                props.runnerInfoEdit.runner_name
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>性別</th>
                                    <td>
                                        <select
                                            onChange={props.handleChange(
                                                'gender'
                                            )}
                                        >
                                            <option value="">性別を選択</option>
                                            <option value="男性">男性</option>
                                            <option value="女性">女性</option>
                                            <option value="その他">
                                                その他
                                            </option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <th>身長</th>
                                    <td>
                                        <HeightSelecter
                                            height={props.runnerInfoEdit.height}
                                            handleHeightChange={props.handleChange(
                                                'height'
                                            )}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div style={{ marginTop: '20px' }}>
                        <div
                            style={{
                                color: 'white',
                                fontSize: '12pt',
                                padding: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            ランナー情報
                        </div>
                        <table id="accountTable">
                            <tbody>
                                <tr>
                                    <th>ランナー名</th>
                                    <td>{props.currentRunner.runner_name}</td>
                                </tr>
                                <tr>
                                    <th>性別</th>
                                    <td>{props.currentRunner.gender}</td>
                                </tr>

                                <tr>
                                    <th>身長</th>
                                    <td>{props.currentRunner.height}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        </div>
    );
};

const RecordView = (props) => {
    const account = props.accountInfo;
    return <div>bbb</div>;
};
