import React from 'react';
import { TextField, Box, Card } from '@material-ui/core';

import { VideoContext, AccountContext } from '../../../App';
import { PageTitle } from '../../common/PageTitle';
import { UploadStepViewer } from './components/UploadStepViewer';
import { RadioChoices } from './components/RadioChoices';
import { UploadButtonWithProgress } from './components/UploadButtonWithProgress';
import Checkbox from '@mui/material/Checkbox';

import { postVideo } from '../../../v1apiRequest';
import { scrollToCheckbox, goToTags, goToSendButton } from './func';

import classes from './upload.module.css';

const createObjectURL =
    (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

export const VideoUploadPage = () => {
    const [file, setFile] = React.useState(null);
    const [videoSrc, setVideoSrc] = React.useState(null);
    const [formContent, setFormContent] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [isChecked, setIsChecked] = React.useState(false);
    const [effort, setEffort] = React.useState(0);
    const [distance, setDistance] = React.useState(10);
    const [phase, setPhase] = React.useState('');
    const [isGuest, setIsGuest] = React.useState('');

    const handleFormChange = (e) => {
        setFormContent(e.target.value);
    };

    React.useEffect(() => {
        if (effort > 10 && phase !== '' && isGuest !== '') {
            setActiveStep(3);
            console.log('go');
            goToSendButton();
        }
    }, [effort, phase, isGuest]);

    const { setVideoSentStatus } = React.useContext(VideoContext);

    const { accountInfo, currentRunner } = React.useContext(AccountContext);

    const chooseVideo = React.useCallback((e) => {
        // ファイル選択がキャンセルされた時は undefined
        const file = e.target.files[0];
        const videoUrl = createObjectURL(file);
        setFile(() => {
            return file ? file : null;
        });
        setVideoSrc(() => {
            return videoUrl ? videoUrl : null;
        });
        setActiveStep(1);
        setIsChecked(false);
        scrollToCheckbox(videoSrc);
    }, []);

    const handleCheckBoxFalse = () => {
        setIsChecked(false);
        setActiveStep(1);
    };

    const handleCheckBoxTrue = () => {
        setIsChecked(true);
        setActiveStep(2);
        goToTags();
    };

    const filesToUpload = new FormData();
    if (activeStep === 3 && file && !!file.name) {
        console.log(file);
        filesToUpload.append('content', file);
        filesToUpload.append('fileName', file.name);
        filesToUpload.append('fps', 120);
        filesToUpload.append('memo', formContent);
        filesToUpload.append('uploaded_by', accountInfo.id);
        filesToUpload.append('effort', effort);
        filesToUpload.append('phase', phase);
        filesToUpload.append('distance', distance);
        filesToUpload.append('runner_id', currentRunner.id);
        filesToUpload.append('is_guest', isGuest);
    }

    const sendCompleteFuncs = () => {
        console.log('OK!!!!');
        setVideoSentStatus({ open: true, status: 'OK' });
        setFile(null);

        setVideoSrc(null);

        setFormContent('');
        setActiveStep(4);
    };

    const sendFailFuncs = (err) => {
        console.log('upload失敗');
        if (err.response.data === 'ビデオが長い') {
            setVideoSentStatus({ open: true, status: 'LONG' });
            return;
        }
        setVideoSentStatus({ open: true, status: 'ERROR' });
    };

    const handleTagChange = (tagName) => (e) => {
        if (tagName === 'phase') {
            setPhase(e.target.value);
        }
        if (tagName === 'distance') {
            setDistance(e.target.value);
        }
        if (tagName === 'effort') {
            setEffort(e.target.value);
        }
        if (tagName === 'is_guest') {
            setIsGuest(e.target.value);
        }
        console.log(e.target.value);
    };

    const phaseOptions = [
        { value: 'middle', label: '中間疾走' },
        { value: 'accelerating', label: '加速中' },
        { value: 'start', label: 'スタート' },
    ];

    const effotOptions = [
        { value: 100, label: '全力走' },
        { value: 80, label: '80-90%' },
        { value: 60, label: '60%以下' },
    ];

    const guestOptions = [
        { value: false, label: 'はい' },
        { value: true, label: 'いいえ（ゲストです）' },
    ];

    return (
        <div id="upload-background">
            <PageTitle title={'ビデオをアップロード'} />

            <UploadStepViewer activeStep={activeStep} />

            <div
                style={{
                    padding: '20px',
                    textAlign: 'center',
                }}
            >
                <div style={{ marginTop: '20px' }}>
                    <label className={classes.inputwrapper}>
                        動画を選択
                        <input
                            type="file"
                            accept="video/*"
                            onChange={chooseVideo}
                        />
                    </label>
                </div>
                <div>
                    {file ? (
                        <div>
                            <video
                                id="v"
                                src={videoSrc}
                                height="96px"
                                width="160px"
                            ></video>
                            <canvas id="c" width="0px" height="0px" />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div style={{ marginTop: '20px' }} id="checkboxes">
                    {file ? ( //file?
                        <div>
                            <div>
                                {isChecked ? (
                                    <Checkbox
                                        checked
                                        onClick={handleCheckBoxFalse}
                                        color="primary"
                                    />
                                ) : (
                                    <Checkbox
                                        checked={false}
                                        onClick={handleCheckBoxTrue}
                                        color="primary"
                                        sx={{ color: '#e6e6fa' }}
                                    />
                                )}
                                <span
                                    style={{
                                        color: 'white',
                                        fontSize: '11pt',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    動画がマニュアル通りに撮影されている
                                </span>

                                <Card
                                    style={{
                                        padding: '2px',
                                        marginTop: '20px',
                                        backgroundColor: '#fff',
                                        width: '99%',
                                        height: `${
                                            window.parent.screen.width * 0.55
                                        }px`,
                                    }}
                                >
                                    <iframe
                                        width="95%"
                                        height="95%"
                                        src={
                                            'https://www.youtube.com/embed/f6YQHKkDKzQ' +
                                            '?rel=0&modestbranding=1'
                                        }
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ marginBottom: '8px' }}
                                    ></iframe>
                                </Card>
                            </div>

                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '5px',
                                }}
                                id="tags"
                            >
                                <TextField
                                    variant="outlined"
                                    label={
                                        <span
                                            style={{
                                                fontSize: '12pt',
                                                color: '#99CCFF',
                                            }}
                                        >
                                            {'動画にメモを追加'}
                                        </span>
                                    }
                                    style={{
                                        width: '100%',
                                        marginTop: '10px',
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: '#202020',
                                            paddingRight: '1px',
                                            color: 'white',
                                        },
                                    }}
                                    multiline
                                    placeholder="（例）出力60%, ピッチ意識, など"
                                    value={formContent}
                                    onChange={handleFormChange}
                                />
                            </div>

                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '5px',
                                }}
                                id="tag"
                            >
                                {' '}
                                <RadioChoices
                                    optionList={phaseOptions}
                                    handleTagChange={handleTagChange}
                                    formLabel="局面"
                                    tag="phase"
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <RadioChoices
                                        optionList={effotOptions}
                                        handleTagChange={handleTagChange}
                                        formLabel="出力"
                                        tag="effort"
                                    />
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <RadioChoices
                                        optionList={guestOptions}
                                        handleTagChange={handleTagChange}
                                        formLabel={
                                            '走者は' +
                                            currentRunner.runner_name +
                                            'さんですか？'
                                        }
                                        tag="is_guest"
                                    />
                                </div>
                            </div>

                            <div id="sendButton">
                                {activeStep === 3 ? (
                                    <UploadButtonWithProgress
                                        activeStep={activeStep}
                                        params={filesToUpload}
                                        completeFunc={sendCompleteFuncs}
                                        failFunc={sendFailFuncs}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
