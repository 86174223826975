import React from 'react';
import { useHistory } from 'react-router-dom';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './fab.css';

export const UploadFAB = ({ goToUploadPage }) => {
    return (
        <div className="fab">
            <Fab
                style={{ backgroundColor: '#7fbfff' }}
                size="large"
                onClick={goToUploadPage}
            >
                <AddIcon style={{ color: '#ffebcd' }} />
            </Fab>
        </div>
    );
};
